import { z } from 'zod'
import * as React from 'react'
import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getSortedRowModel,
  SortingState,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table'
import {
  closestCenter,
  DndContext,
  type DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  type UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { Tabs, TabsContent } from '@/components/ui/tabs'
import { Label } from '@/components/ui/label'
import { Button as NextUIButton, cn, Spinner } from '@nextui-org/react'
import { Button } from '@/components/ui/button'
import { IconAlertTriangle, IconCircleCheckFilled, IconLoader, IconPlus } from '@tabler/icons-react'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { schema } from '@/pages/powerdoc-project-view'
import DragHandle from '@/pages/powerdoc-project-view/components/DragHandle'
import DraggableRow from '@/pages/powerdoc-project-view/components/DraggableRow'
import { TableCellViewer } from '@/pages/powerdoc-project-view/components/TableCellViewer'
import { CalendarIcon, DownloadIcon, EyeIcon, PlusIcon, SendIcon } from 'lucide-react'
import { Calendar } from 'src/components/ui/calendar'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { format } from 'date-fns'
import CreatableSelect from 'react-select/creatable'
import { useMemo, useState } from 'react'
import { useSocketContextData } from '@/context/socket'
import UserCell from '@/pages/team/user-cell'
import { PowerDocsEventsName } from '@/types/SocketEvents'
import dayjs from 'dayjs'
import DocumentPreview from '@/pages/powerdoc-view/DocumentPreview'
import { baseURL } from '@/lib/api'
import { useParams } from 'react-router-dom'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
} from 'src/components/ui/dropdown-menu'

export default function DataTable({
  data: initialData,
  members,
  isLoading,
  variant,
  setVariant,
  onCreateVariantClick,
  isGenerating,
  addSectionClicked,
}: {
  data: z.infer<typeof schema>[]
  members: {
    id: number
    firstname: string
    lastname: string
    email: string
  }[]
  isLoading: boolean
  variant: string
  setVariant: (variant: 'PROPOSAL' | 'QUOTATION' | 'OFFER') => void
  onCreateVariantClick: () => void
  isGenerating: boolean
  addSectionClicked: () => void
}) {
  const { io } = useSocketContextData()
  const columns: ColumnDef<z.infer<typeof schema>>[] = useMemo(
    () => [
      {
        id: 'drag',
        header: () => null,
        cell: ({ row }) => <DragHandle id={row.original.id} />,
        enableSorting: false,
      },
      {
        id: 'select',
        header: () => null,
        cell: ({ row }) => <div className="flex items-center justify-center"></div>,
        enableSorting: false,
        enableHiding: false,
      },
      {
        accessorKey: 'title',
        header: 'Title',
        cell: ({ row }) => {
          return <TableCellViewer item={row.original} />
        },
        enableHiding: false,
        enableSorting: false,
      },
      {
        accessorKey: 'status',
        header: 'Status',
        cell: ({ row }) => {
          const [status, setStatus] = useState<string>(row.original.status)
          const updateStatus = (value: string) => {
            setStatus(value)
            io.emit(PowerDocsEventsName.UPDATE_SECTION_STATUS, {
              status: value,
              token: localStorage.getItem('accessToken'),
              sectionId: row.original.id,
            })
          }
          return (
            <DropdownMenu>
              <DropdownMenuTrigger asChild className={'focus:outline-none'}>
                <Button
                  variant="ghost"
                  className="focus:none data-[state=open]:bg-muted text-muted-foreground flex size-8"
                  size="icon"
                >
                  {status === 'DRAFT' ? (
                    <>
                      <IconAlertTriangle color={'#ffcc00'} />
                      Draft
                    </>
                  ) : status === 'IN_PROCESS' ? (
                    <>
                      <IconLoader />
                      In progress
                    </>
                  ) : status === 'NEED_REVIEW' ? (
                    <>
                      <IconAlertTriangle color={'#339900'} size={16} />
                      Needs Review
                    </>
                  ) : (
                    <>
                      <IconCircleCheckFilled color={'#339900'} size={16} />
                      Done
                    </>
                  )}
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="w-32 bg-white cursoir-pointer">
                <DropdownMenuItem
                  onSelect={() => {
                    updateStatus('DRAFT')
                  }}
                >
                  Draft
                </DropdownMenuItem>
                <DropdownMenuItem
                  onSelect={() => {
                    updateStatus('IN_PROCESS')
                  }}
                >
                  In Progress
                </DropdownMenuItem>
                <DropdownMenuItem
                  onSelect={() => {
                    updateStatus('DONE')
                  }}
                >
                  Done
                </DropdownMenuItem>
                <DropdownMenuItem
                  onSelect={() => {
                    updateStatus('NEED_REVIEW')
                  }}
                >
                  Needs Review
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          )
        },
        enableSorting: false,
      },
      {
        accessorKey: 'dueDate',
        header: () => <div className="w-full text-left">Due Date</div>,
        cell: ({ row }) => {
          const [dueDate, setDueDate] = useState<string>(row.original.dueDate)
          const [calendarOpen, setCalendarOpen] = useState(false)
          const { io } = useSocketContextData()
          const updateDueDate = (value: Date) => {
            setDueDate(value as unknown as string)
            setCalendarOpen(false)
            io.emit(PowerDocsEventsName.UPDATE_SECTION_DUE_DATE, {
              dueDate: value,
              sectionId: row.original.id,
              token: localStorage.getItem('accessToken'),
            })
          }
          return (
            <Popover open={calendarOpen} onOpenChange={setCalendarOpen}>
              <PopoverTrigger asChild>
                <Button
                  variant={'outline'}
                  className={cn(
                    'w-[120] justify-start text-left font-normal items-center text-sm align-middle border-none shadow-none box-shadow-none',
                    !dueDate && 'text-default-500',
                    dueDate && dayjs().isAfter(dayjs(dueDate)) && 'border-danger',
                  )}
                >
                  <CalendarIcon size={16} />
                  {dueDate ? format(dueDate, 'PPP') : <span>Pick a date</span>}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0 bg-white" align="start">
                <Calendar
                  initialFocus
                  mode="single"
                  selected={dueDate as unknown as Date}
                  //@ts-ignore
                  onSelect={updateDueDate}
                />
              </PopoverContent>
            </Popover>
          )
        },
        enableSorting: false,
      },
      {
        accessorKey: 'lastEdit',
        header: () => <div className="w-full text-left">Last Edit</div>,
        cell: ({ row }) => (
          <Button
            variant={'outline'}
            className={cn(
              'w-[120] justify-start text-left font-normal items-center text-sm align-middle border-none shadow-none',
              !row.original.editDate && 'text-default-500',
            )}
          >
            <CalendarIcon size={16} />
            {row.original.editDate ? format(row.original.editDate, 'PPP') : <span>No Edit yet</span>}
          </Button>
        ),
        enableSorting: false,
      },
      {
        accessorKey: 'reviewer',
        header: 'Reviewer',
        cell: ({ row }) => {
          const [selectedMember, setSelectedMember] = useState<any>(
            row.original.permissions?.[0]?.user
              ? {
                  value: row.original.permissions?.[0]?.user?.email,
                  label: (
                    <h1>
                      {[
                        row.original.permissions?.[0]?.user?.firstname,
                        row.original.permissions?.[0]?.user?.lastname,
                      ].join(' ')}
                    </h1>
                  ),
                }
              : {},
          )
          const [loading, setLoading] = useState<boolean>(false)
          const asignSectionToMember = async (email: string) => {
            setLoading(true)
            await io.emitWithAck(PowerDocsEventsName.ASSIGN_TO_MEMBER, {
              sectionId: row.original.id,
              email,
              token: localStorage.getItem('accessToken'),
            })
            setLoading(false)
          }
          return (
            <div className={'bg-white h-[40]'}>
              <CreatableSelect
                options={members.map((item: any) => ({
                  value: item.email,
                  label: `${item.firstname} ${item.lastname}`,
                }))}
                isDisabled={loading}
                placeholder="Assign to team member"
                value={selectedMember}
                onChange={(data) => {
                  setSelectedMember(data)
                  asignSectionToMember(data.value)
                }}
                onCreateOption={(inputValue: string) => {
                  setSelectedMember({ label: inputValue, value: inputValue })
                  setLoading(true)
                }}
                formatCreateLabel={(inputValue: string) => `Create new client: ${inputValue}`}
                menuPosition="fixed"
                menuPortalTarget={document.body}
                formatOptionLabel={(option, { context }) => {
                  return context === 'menu' ? (
                    <>
                      <UserCell avatar="img/icon.png" name={option.label} permission={''} />
                    </>
                  ) : (
                    option.label
                  )
                }}
                styles={{
                  container: (baseStyles) => ({
                    ...baseStyles,
                    minWidth: 120,
                    borderColor: '#e5e7eb',
                    borderWidth: 2,
                    borderRadius: 8,
                    padding: 0,
                    height: '100%',
                    boxShadow: 'none',
                  }),
                  control: (baseStyles) => ({
                    ...baseStyles,
                    border: 'none',
                    height: '100%',
                    width: '100%',
                    outline: 'none',
                    boxShadow: 'none',
                  }),
                  menuPortal: (defaultStyles) => ({
                    ...defaultStyles,
                    zIndex: 99999,
                  }),
                }}
              />
            </div>
          )
        },
        enableSorting: false,
      },
    ],
    [members],
  )

  const [data, setData] = React.useState(() => initialData)

  const [rowSelection, setRowSelection] = React.useState({})
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({})
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([])
  const [sorting, setSorting] = React.useState<SortingState>([])

  const sortableId = React.useId()
  const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}))

  const dataIds = React.useMemo<UniqueIdentifier[]>(() => data?.map(({ id }) => id) || [], [data, initialData])
  const inActiveButtonClass = 'bg-transparent text-default-400 pl-5 pr-5'
  const activeButtonClass = 'text-black-500 font-medium bg-white rounded-md  pl-5 pr-5'

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
    },
    getRowId: (row) => row.id.toString(),
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  })

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event
    if (active && over && active.id !== over.id) {
      const oldIndex = dataIds.indexOf(active.id)
      const newIndex = dataIds.indexOf(over.id)
      io.emit(PowerDocsEventsName.REORDER_POWERDOC_SECTIONS, {
        variant,
        oldIndex,
        newIndex,
        dragged: active.id,
        dropped: over.id,
        documentUUID: params.documentUUID,
        token: localStorage.getItem('accessToken'),
      })
      setData((data) => {
        const oldIndex = dataIds.indexOf(active.id)
        const newIndex = dataIds.indexOf(over.id)
        return arrayMove(data, oldIndex, newIndex)
      })
    }
  }
  const params = useParams()
  const [isPreview, setIsPreview] = useState<boolean>(false)
  const downloadPdf = () => {
    const popup = window.open(`${baseURL}/powerdocs/export/${params.documentUUID}/${variant}`)
    if (popup) {
      popup?.focus()
      popup.addEventListener('unload', () => {
        popup.close()
      })
    }
  }

  return (
    <Tabs defaultValue="outline" className="w-full flex-col justify-start gap-6">
      <div className="flex items-center justify-between px-4 lg:px-6">
        <Label htmlFor="view-selector" className="sr-only">
          View
        </Label>
        <div className="flex w-fit @4xl/main:hidden">
          <div className={'p-1 rounded-xl w-fit bg-default-100'}>
            {['Proposal', 'Quotation', 'Offer'].map((item) => (
              <NextUIButton
                size={'sm'}
                className={variant.toLowerCase() === item.toLowerCase() ? activeButtonClass : inActiveButtonClass}
                onPress={() => setVariant(item.toUpperCase())}
              >
                {item}
              </NextUIButton>
            ))}
          </div>
        </div>
        <div className="flex items-center gap-2">
          <Button variant="outline" size="sm" className={'rounded-3xl'} onClick={() => setIsPreview(true)}>
            <EyeIcon size={16} />
            <span className="hidden lg:inline">Preview</span>
          </Button>
          <Button variant="outline" size="sm" className={'rounded-3xl'}>
            <SendIcon size={16} />
            <span className="hidden lg:inline">Send</span>
          </Button>
          <Button variant="outline" size="sm" className={'rounded-3xl'} onClick={downloadPdf}>
            <DownloadIcon size={16} />
            <span className="hidden lg:inline">Download</span>
          </Button>
        </div>
      </div>
      {isPreview && (
        <div className={'flex absolute top-0 left-0 w-full h-full z-50 bg-zinc-800'}>
          <div className="flex w-full h-full">
            <DocumentPreview
              url={`${baseURL}/powerdocs/preview/${params.documentUUID}/${variant}`}
              close={() => setIsPreview(false)}
            />
          </div>
        </div>
      )}
      <TabsContent value="outline" className="relative flex flex-col gap-4 overflow-auto px-4 lg:px-6">
        <div className="overflow-hidden rounded-lg">
          <DndContext
            collisionDetection={closestCenter}
            modifiers={[restrictToVerticalAxis]}
            onDragEnd={handleDragEnd}
            sensors={sensors}
            id={sortableId}
          >
            <Table className="border-0">
              <TableHeader className="sticky top-0 z-10">
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id} className={'!border-b-0'}>
                    {headerGroup.headers.map((header, index) => {
                      return (
                        <TableHead
                          key={header.id}
                          colSpan={header.colSpan}
                          className={`font-regular text-xs text-[#09090B] bg-[#C3E3E8] ${
                            index === 0 ? 'rounded-tl-xl rounded-bl-xl' : ''
                          } ${index === headerGroup.headers.length - 1 ? 'rounded-tr-xl rounded-br-xl' : ''}`}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())}
                        </TableHead>
                      )
                    })}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody className="**:data-[slot=table-cell]:first:w-8">
                {table.getRowModel().rows?.length ? (
                  <SortableContext items={dataIds} strategy={verticalListSortingStrategy}>
                    {table.getRowModel().rows.map((row) => (
                      <DraggableRow key={row.id} row={row} />
                    ))}
                  </SortableContext>
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length} className="h-24 text-center">
                      {isLoading ? (
                        <div className={'items-center gap-2 flex-row flex justify-center'}>
                          <Spinner size={'sm'} />
                          <span>Loading sections</span>
                        </div>
                      ) : (
                        <div className={'items-center gap-4 pt-4 flex-col flex justify-center'}>
                          <span className={'text-xs italic font-bold text-default-300'}>No Section created yet</span>
                          <NextUIButton color={'secondary'} onPress={onCreateVariantClick} isDisabled={isGenerating}>
                            <PlusIcon />
                            Generate {variant.toLowerCase()} now
                          </NextUIButton>
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </DndContext>
        </div>
        <div className="flex items-center justify-between px-4">
          <div className="text-muted-foreground hidden flex-1 text-sm lg:flex">
            <div className={'flex flex-row gap-1 items-center cursor-pointer'} onClick={addSectionClicked}>
              <IconPlus size={20} color={'#3F3F46'} />
              <span className={'text-medium text-[#3F3F46] font-medium'}>Add section</span>
            </div>
          </div>
          <div className="flex w-full items-center gap-8 lg:w-fit"></div>
        </div>
      </TabsContent>
      <TabsContent value="past-performance" className="flex flex-col px-4 lg:px-6">
        <div className="aspect-video w-full flex-1 rounded-lg border border-dashed"></div>
      </TabsContent>
      <TabsContent value="key-personnel" className="flex flex-col px-4 lg:px-6">
        <div className="aspect-video w-full flex-1 rounded-lg border border-dashed"></div>
      </TabsContent>
    </Tabs>
  )
}
