import { useState, useEffect } from 'react'
import { Button, ButtonGroup, cn } from '@nextui-org/react'
import { Icon } from '@iconify/react'
import { useSocketContextData } from 'src/context/socket'

import Mixpanel from 'src/lib/mixpanel'

const FeedbackComponent = ({ documentUUID }: { documentUUID: string }) => {
  const [feedbackStage, setFeedbackStage] = useState<string>('initial')
  const [visibleStage, setVisibleStage] = useState<string>('initial')
  const { io } = useSocketContextData()
  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    if (feedbackStage !== visibleStage) {
      // Delay state change to allow for fade-out effect
      setTimeout(() => {
        setVisibleStage(feedbackStage)
      }, 300) // Match duration of the transition
    }
  }, [feedbackStage, visibleStage])

  const handleFeedback = (value: number) => {
    // Track feedback submission
    Mixpanel.track('Document feedback submitted', {
      feedback_score: value,
      document_id: documentUUID,
      location: 'Document',
    })

    if (value >= 0 && value <= 6) {
      setFeedbackStage('feedbackOffer')
    } else if (value >= 7 && value <= 8) {
      setFeedbackStage('feedbackOffer')
    } else if (value === 9 || value === 10) {
      setFeedbackStage('thankYou')
    }
    io.emit('rateDocument', {
      documentUUID,
      score: value,
      token: localStorage.getItem('accessToken'),
    })
  }

  return (
    isVisible && (
      <div className="flex flex-col w-full justify-center pt-5 pb-2 px-20 max-w-[800px]">
        <div
          className={cn(
            'flex-col gap-2 transition-opacity duration-300 ease-in',
            feedbackStage === 'initial' ? 'opacity-100' : 'opacity-0 pointer-events-none',
            visibleStage === 'initial' ? 'flex' : 'hidden',
          )}
        >
          <div className="flex justify-between">
            <div className="w-[50px]">&nbsp;</div>
            <div
              className="text-sm text-center rounded-full backdrop-blur py-2 px-4 w-fit shadow"
              style={{ backgroundColor: 'rgba(195, 227, 232, 0.5)' }}
            >
              How did your document turn out?
            </div>
            <div className="w-[50px] flex justify-end items-center">
              <div
                className="p-1 rounded-full cursor-pointer shadow"
                style={{ backgroundColor: 'rgba(195, 227, 232, 0.5)' }}
                onClick={() => {
                  // Track closing of the Feedback Bar
                  Mixpanel.track('Document feedback closed', {
                    location: 'Document',
                  })

                  // Close the Feedback Bar
                  setIsVisible(false)
                }}
              >
                <Icon icon="lucide:circle-x" width="24" />
              </div>
            </div>
          </div>
          <div
            className="flex justify-center w-full rounded-full backdrop-blur items-center px-2 shadow"
            style={{ backgroundColor: 'rgba(195, 227, 232, 0.5)' }}
          >
            <Icon icon="twemoji:confused-face" width="28" onClick={() => handleFeedback(0)} />
            <ButtonGroup fullWidth size="md" variant="light">
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => (
                <Button key={num} onPress={() => handleFeedback(num)}>
                  {num}
                </Button>
              ))}
            </ButtonGroup>
            <Icon icon="twemoji:smiling-face-with-open-mouth" width="28" onClick={() => handleFeedback(10)} />
          </div>
        </div>

        <div
          className={cn(
            'flex flex-row w-fit self-center items-center justify-center gap-3 transition-opacity duration-300 ease-in backdrop-blur rounded-full px-4 py-2 shadow',
            feedbackStage === 'feedbackOffer' ? 'opacity-100' : 'opacity-0 pointer-events-none',
            visibleStage === 'feedbackOffer' ? 'flex' : 'hidden',
          )}
          style={{ backgroundColor: 'rgba(195, 227, 232, 0.5)' }}
        >
          <p className="m-0 flex items-center">Share your feedback with our CEO</p>
          <Button
            size="md"
            color="secondary"
            className="mt-0"
            onPress={() => (window.location.href = 'mailto:marvin@powerdocs.so?subject=Feedback%20on%20my%20document')}
          >
            Send an email
          </Button>
          <Button
            size="md"
            color="secondary"
            className="mt-0"
            onPress={() => window.open('https://powerdocs.so/meeting', '_blank')}
          >
            Set up a video call
          </Button>
        </div>

        <div
          className={cn(
            'flex flex-row items-center justify-center self-center w-fit transition-opacity duration-300 ease-in backdrop-blur rounded-full px-4 py-2 shadow',
            feedbackStage === 'thankYou' ? 'opacity-100' : 'opacity-0 pointer-events-none',
            visibleStage === 'thankYou' ? 'flex' : 'hidden',
          )}
          style={{ backgroundColor: 'rgba(195, 227, 232, 0.5)' }}
        >
          <p className="text-center flex items-center">
            <Icon icon="solar:heart-bold" className="mr-1" /> Thank you for your feedback!
          </p>
        </div>
      </div>
    )
  )
}

export default FeedbackComponent
