import React, { useEffect, useCallback, useState, forwardRef, useImperativeHandle, useRef, Ref } from 'react'
import debounce from 'lodash.debounce'
import DragHandle from '@tiptap-pro/extension-drag-handle-react'
import { useEditor, EditorContent, BubbleMenu } from '@tiptap/react'
import { useSocketContextData } from 'src/context/socket'
import {
  Spinner,
  Tooltip,
  ScrollShadow,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Input,
  Textarea,
} from '@nextui-org/react'
import Mixpanel from 'src/lib/mixpanel'

import { Icon } from '@iconify/react'

import './editor-styles.css'
import { createSuggestions } from 'src/pages/powerdoc-view/extensions/SlashPlugin'
import { createExtensions } from 'src/pages/powerdoc-view/extensions/extensions'
import { Decoration, DecorationSet } from '@tiptap/pm/view'
import ReactDOM from 'react-dom/client'
import QuestionsContainer from 'src/pages/powerdoc-view/extensions/QuestionsContainer'
import DocMenuBar from 'src/pages/powerdoc-view/extensions/DocMenuBar'
import CoverPage from 'src/pages/powerdoc-view/extensions/cover-page'
import { useWorkspaceContextData } from 'src/context/workspace'
import { AddMarkStep } from '@tiptap/pm/transform'
import { Editor } from '@tiptap/core'
import Cookie from 'js-cookie'
import { PowerDocsEventsName } from '@/types/SocketEvents'
let cache: any
let cacheLength = -1

interface Comment {
  id: number
  uuid: string
  text: string
  username: string
  documentId: string
  isResolved: boolean
  createdAt: string
}

// eslint-disable-next-line react/display-name
export const TipTapEditor = forwardRef(
  (
    {
      currentUserWithPermission,
      markdown,
      documentUUID,
      isReadOnly,
      docDesignSettings,
      doc,
      onQuestionAnswer,
      variant,
      setDoc,
    }: {
      currentUserWithPermission: {
        sections: any[]
        canEditTheWholeDocument: boolean
      }
      markdown: string
      documentUUID?: string
      isReadOnly?: boolean
      docDesignSettings: {
        fontType: string
        fontSize: number
        sectionSpacing: number
        skipUpdate?: boolean
      }
      doc: any
      onQuestionAnswer?: (answer: string[], questionIndex: number) => Promise<void>
      variant: 'quotation' | 'proposal' | 'offer'
      setDoc: (doc: any) => void
    },
    ref,
  ) => {
    const [tableContent, setTableContent] = useState<any[]>([])
    const [loadingError, setLoadingError] = useState<boolean>(false)
    const [loadingImage, setLoadingImage] = useState<boolean>(false)
    const [showCoverPageEdit, setShowCoverPageEdit] = useState<boolean>(false)
    const [imageToEdit, setImageToEdit] = useState<any>(null)
    const [commentText, setCommentText] = useState<string>('')
    const [displayName, setDisplayName] = useState<string>('')
    const [showDisplayName, setShowDisplayName] = useState<boolean>(false)
    const [showComment, setShowComment] = useState<boolean>(false)
    const [selectedComment, setSelectedComment] = useState<any>()
    const { isOpen: isMediaModalOpen, onOpen: onOpenMediaModal, onOpenChange: onOpenChangeMediaModal } = useDisclosure()
    const suggestions = createSuggestions({ onOpenMediaModal })
    const onCommentClicked = (commentId: string, rect: any) => {
      const comment = doc?.comments?.filter((item: any) => item.uuid === commentId)?.pop()
      if (comment) {
        setShowComment(true)
        setSelectedComment({ ...comment })
        const element = document.getElementById('comment-section')
        const tiptap = document.getElementsByClassName('tiptap')?.[0]?.getBoundingClientRect()
        if (element) {
          element.style.left = `${tiptap.left + tiptap.width}px`
          element.style.top = `${rect.top + window.scrollY}px`
        }
      }
    }
    const extensions = createExtensions({
      currentUserWithPermission,
      setTableContent,
      suggestions,
      onCommentClicked,
      setShowComment,
    })
    const {
      isOpen: isCommentModalOpen,
      onOpen: setIsCommentModalOpen,
      onClose: closeCommentModal,
      onOpenChange: commentModalOpenChange,
    } = useDisclosure()

    const [saveMessage, setSaveMessage] = useState<{ text: string; visible: boolean }>({
      text: '',
      visible: false,
    })
    const { profile } = useWorkspaceContextData()
    useEffect(() => {
      if (profile?.firstname || profile?.lastname) {
        setDisplayName(`${profile.firstname ?? ''} ${profile.lastname ?? ''}`)
      } else {
        const name = Cookie.get('displayName')
        if (name) {
          setDisplayName(name)
        } else {
          setShowDisplayName(true)
        }
      }
    }, [profile, isCommentModalOpen])
    const updateDoc = async (editor: Editor, isItComment: boolean) => {
      if (documentUUID && (!isReadOnly || isItComment)) {
        setSaveMessage({ text: 'Saving document...', visible: true })

        const content = editor.storage.markdown.getMarkdown()
        await io.emitWithAck(PowerDocsEventsName.UPDATE_POWERDOC_CONTENT, {
          content,
          variant: window.variant,
          documentUUID,
          token: localStorage.getItem('accessToken'),
          isItComment,
        })
        setSaveMessage({ text: 'Document saved', visible: true })
        setTimeout(() => {
          setSaveMessage((prev) => ({ ...prev, visible: false }))
        }, 500)
      }
    }
    const debouncedUpdate = useCallback(
      debounce(updateDoc, 500), // Delay of 500ms
      [],
    )

    useEffect(() => {
      window.variant = variant
    }, [variant])
    const getNewComment = async (comment: string, username: string) => {
      const res = await io.emitWithAck('create_comment', {
        username,
        comment,
        token: localStorage.getItem('accessToken'),
        documentUUID: doc.uuid,
        variant,
      })
      if (!res.uuid) return null
      return res as unknown as Comment
    }

    const editor = useEditor({
      extensions,
      content: markdown,
      onUpdate: ({ editor, transaction }) => {
        if (isReadOnly) {
          if (!transaction.steps.some((step) => !(step instanceof AddMarkStep))) {
            updateDoc(editor, true)
          }
        } else {
          debouncedUpdate(editor, false)
        }
      },
      editorProps: {
        decorations: (state) => {
          const { doc: tiptapDoc, tr } = state
          const decorations: Decoration[] = []
          let questionAreas = 0
          tiptapDoc.descendants((node, pos) => {
            if (node?.type?.name === 'heading' && node?.attrs?.level === 2) {
              const headingText = node.textContent.trim()
              const questions = doc.questions?.filter(
                (item: any) => item?.chapter?.trim().toLowerCase() === headingText.toLowerCase(),
              )
              if (questions?.length > 0) {
                questionAreas++
              }
            }
          })
          if (cache?.filter((item: any) => item).length === cacheLength && questionAreas === cacheLength) {
            return DecorationSet.create(tiptapDoc, cache)
          }
          tiptapDoc.descendants((node, pos) => {
            if (node.type.name === 'heading' && node.attrs.level === 2) {
              const headingText = node.textContent.trim()
              const questions = doc.questions
                ?.map((item: any, index: number) => ({ ...item, index }))
                ?.filter((item: any) => item?.chapter?.trim().toLowerCase() === headingText.toLowerCase())
              if (questions?.length > 0) {
                decorations.push(
                  Decoration.widget(pos, () => {
                    const container = document.createElement('div')
                    container.style.position = 'relative' // Ensure it's positioned relative to the heading

                    // Create the helper text element
                    const helperDiv = document.createElement('div')
                    const helperTextContainer = (
                      <QuestionsContainer questions={questions} onQuestionAnswer={onQuestionAnswer} />
                    )
                    const root = ReactDOM.createRoot(helperDiv)
                    root.render(helperTextContainer)

                    // Append the helper text above the heading
                    container.appendChild(helperDiv)
                    return container
                  }),
                )
              }
            }
          })
          cache = decorations
          cacheLength = decorations.filter((item) => item).length
          return DecorationSet.create(tiptapDoc, decorations)
        },
      },
    })
    const [modalName, setModalName] = useState<string>('')
    const {
      isOpen: isNameModalOpen,
      onOpenChange: onNameModalOpenChange,
      onOpen: onNameModalOpen,
      onClose: onNameModalClose,
    } = useDisclosure()
    useEffect(() => {
      const cookieName = Cookie.get('displayName')
      if (isReadOnly && !cookieName && !profile?.uuid) {
        onNameModalOpen()
      }
    }, [isReadOnly, profile])
    useEffect(() => {
      if (!editor) return

      // Define base class
      const baseClass = 'prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto focus:outline-none tiptap'

      // Append dynamic classes
      const fontSizeClass =
        docDesignSettings?.fontSize === 1 ? 'small' : docDesignSettings?.fontSize === 2 ? 'medium' : 'large'
      const spacingClass = `spacing-${docDesignSettings?.sectionSpacing}`

      // Ensure classes are correctly concatenated
      const finalClass = `${baseClass} ${fontSizeClass} ${spacingClass}`.trim()

      editor.setOptions({
        editorProps: {
          attributes: {
            class: finalClass,
            style: `font-family: "${docDesignSettings?.fontType}", sans-serif;`,
          },
        },
      })
    }, [docDesignSettings, editor])

    useEffect(() => {
      if (editor) {
        editor.commands.setContent(markdown)
      }
    }, [markdown, editor])

    useEffect(() => {
      if (isReadOnly) {
        const element = document.getElementsByClassName('tiptap')?.[0]
        if (element) {
          element.addEventListener('keydown', (e) => e.preventDefault())
        }
      }
    }, [editor, isReadOnly])
    useEffect(() => {
      return () => {
        if (editor) {
          editor.destroy() // Clean up the editor on unmount
        }
        // Cancel the debounce on unmount to prevent memory leaks
        debouncedUpdate.cancel()
      }
    }, [editor, debouncedUpdate])

    const { io } = useSocketContextData()

    useImperativeHandle(
      ref,
      () => {
        return {
          getMarkdown: () => {
            return editor?.storage.markdown.getMarkdown()
          },
          setMarkdown: (content: string) => {
            editor?.commands?.setContent(content)
          },
          unsetComment: (commentUUID: string) => {
            editor?.commands?.unsetComment(commentUUID)
          },
        }
      },
      [editor],
    )
    useEffect(() => {
      if (onOpenMediaModal) {
        //@ts-ignore
        window.documentEditImage = (imageRef: Ref<HTMLElement | null>) => {
          setImageToEdit(imageRef)
          onOpenMediaModal()
        }
      }
    }, [onOpenMediaModal])

    const scrollToItem = (id: string) => {
      const headingElement = document.getElementById(id)
      console.log(headingElement, id)
      if (headingElement) {
        headingElement.scrollIntoView({ behavior: 'smooth' })

        // Extract text from <strong> element if it exists
        const strongElement = headingElement.querySelector('strong')
        const headingText = headingElement ? headingElement.textContent : 'Unknown'

        // Track the scroll event for Table of Content navigation
        Mixpanel.track('Table of content item clicked', {
          heading_text: headingText,
          element_id: id,
        })

        headingElement.classList.add('highlight')
        setTimeout(() => {
          headingElement.classList.remove('highlight') // Remove highlight after 2 seconds
        }, 2000)
      }
    }

    const imageUrlInput = useRef(null)
    const activeNodePos = useRef<number>(-1)
    const createNewBlock = () => {
      if (!editor) return
      const { state, view } = editor
      const transaction = state.tr.insert(activeNodePos.current, state.schema.nodes.paragraph.create())
      transaction.insertText('', activeNodePos.current + 1)
      view.dispatch(transaction)
      editor
        .chain()
        .focus()
        .setTextSelection(activeNodePos.current + 1)
        .run()
    }
    const onNodeChange = useCallback((props) => {
      const { node, pos } = props
      if (node) {
        activeNodePos.current = pos + node.nodeSize
      } else {
        activeNodePos.current = -1
      }
    }, [])

    const addNewComment = async () => {
      const newComment = await getNewComment(commentText, displayName)
      if (newComment) {
        editor?.commands.setComment(newComment.uuid)
        closeCommentModal()
        Cookie.set('displayName', displayName)
        setShowDisplayName(false)
        setDoc((prev: any) => {
          switch (variant.toUpperCase()) {
            case 'PROPOSAL':
              return {
                ...prev,
                proposal: {
                  ...prev.proposal,
                  comments: [newComment, ...(prev.proposal?.comments ?? [])],
                },
              }
            case 'QUOTATION':
              return {
                ...prev,
                quotation: {
                  ...prev.quotation,
                  comments: [newComment, ...(prev.quotation?.comments ?? [])],
                },
              }
            case 'OFFER':
              return {
                ...prev,
                offer: {
                  ...prev.offer,
                  comments: [newComment, ...(prev.offer?.comments ?? [])],
                },
              }
          }
          return prev
        })
      }
    }
    const resolveComment = async () => {
      if (selectedComment) {
        const commentUUID = selectedComment.uuid
        editor?.commands.unsetComment(commentUUID)
        setShowComment(false)
        io.emit('resolve_comment', {
          token: localStorage.getItem('accessToken'),
          documentUUID: doc.uuid,
          commentUUID,
        })
        setDoc((prev: any) => {
          switch (variant.toUpperCase()) {
            case 'PROPOSAL':
              return {
                ...prev,
                proposal: {
                  ...prev.proposal,
                  comments: (prev.proposal?.comments ?? []).map((comment: any) => {
                    if (comment.uuid === commentUUID) {
                      return {
                        ...comment,
                        isResolved: true,
                      }
                    } else {
                      return comment
                    }
                  }),
                },
              }
            case 'QUOTATION':
              console.log(prev)
              return {
                ...prev,
                quotation: {
                  ...prev.quotation,
                  comments: (prev.quotation?.comments ?? []).map((comment: any) => {
                    console.log(comment, commentUUID)
                    if (comment.uuid === commentUUID) {
                      return {
                        ...comment,
                        isResolved: true,
                      }
                    } else {
                      return comment
                    }
                  }),
                },
              }
            case 'OFFER':
              return {
                ...prev,
                offer: {
                  ...prev.offer,
                  comments: (prev.offer?.comments ?? []).map((comment: any) => {
                    if (comment.uuid === commentUUID) {
                      return {
                        ...comment,
                        isResolved: true,
                      }
                    } else {
                      return comment
                    }
                  }),
                },
              }
          }
          return prev
        })
        setSelectedComment({})
      }
    }
    if (!editor) {
      return null
    }
    return (
      <>
        {!isReadOnly && (
          <DocMenuBar
            editor={editor}
            content={markdown}
            documentUUID={documentUUID}
            saveMessage={saveMessage}
            doc={doc}
            activePage={variant}
          />
        )}
        {!isReadOnly && <CoverPage doc={doc} isVisible={showCoverPageEdit} close={() => setShowCoverPageEdit(false)} />}

        {!isReadOnly && (
          <div
            className={
              'flex max-w-full w-[794px] justify-center text-md font-medium cursor-pointer rounded-full bg-[#E8F2F4] p-2 mt-20'
            }
            onClick={() => {
              setShowCoverPageEdit(true)

              // Mixpanel Tracking Event
              Mixpanel.track('Cover Page Edit Clicked', {
                document_id: documentUUID,
                user_action: 'Opened Cover Page Editor',
                timestamp: new Date().toISOString(),
              })
            }}
          >
            COVER PAGE <Icon icon={'lucide:edit'} width="24" className="ml-1" />
          </div>
        )}
        {isReadOnly && (
          <div className={' max-w-full h-[280mm] w-[794px]'}>
            <div
              // className="bg-white rounded-lg p-8 w-[210mm] h-[297mm] border rounded-xl"
              className="bg-white rounded-lg border rounded-xl w-[100%] h-full"
              style={{
                padding: '0.75in',
                // borderWidth: '0.75in',
                borderColor: 'none',
              }}
              id={'cover-page-component'}
            >
              <div className="cover-page flex flex-col h-full justify-between">
                {/* Logo Section */}
                {doc.coverLogo && (
                  <div className="flex justify-start w-full">
                    <img src={doc.coverLogo} className="w-[150px]" />
                  </div>
                )}

                {/* Title Section */}
                <div className="flex-1 flex flex-col w-full items-left justify-center">
                  <div className="text-lg">{doc.documentDate}</div>
                  <div className="text-3xl font-bold">{doc.title}</div>
                </div>

                {/* Footer Section - Created By & Proposed To */}
                <div className="flex flex-row w-full gap-4">
                  {/* Left Column - Created By */}
                  <div className="w-1/2 flex flex-col">
                    <div className="font-semibold text-lg">Created by</div>
                    <div className="whitespace-pre-wrap">
                      {doc.createdBy?.split('\n').map((item, index) => (
                        <span key={index} className="block">
                          {item}
                        </span>
                      ))}
                    </div>
                  </div>

                  {/* Right Column - Proposed To */}
                  <div className="w-1/2 flex flex-col">
                    <div className="font-semibold text-lg">Prepared for</div>
                    <div className="whitespace-pre-wrap">
                      {doc.proposedTo?.split('\n').map((item, index) => (
                        <span key={index} className="block">
                          {item}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className={'flex max-w-full w-[794px] border rounded-xl px-2 md:px-20 py-2 md:py-8 my-[20px]'}>
          <div className={'toc-wrapper'}>
            <div className={'toc'}>
              <div className={'toc-mini p-2'}>
                {tableContent
                  .filter((item) => item.level < 3)
                  .map((item) => (
                    <div key={item.label} className="toc-item-mini toc-light">
                      ▃▃
                    </div>
                  ))}
              </div>
              <div className="toc-list flex flex-col shadow-md rounded-xl h-[300px] p-4 text-default overflow-y-scroll bg-white w-[250px] scrollbar-hide opacity-0 transition-opacity duration-300 hover:opacity-100 hover:block hidden">
                <ScrollShadow className="mb-3" hideScrollBar size={40}>
                  {tableContent.map((item, index) => (
                    <div
                      onClick={() => scrollToItem(item.id)}
                      title={item.textContent}
                      className="toc-item cursor-pointer text-default-700 text-xs p-2 rounded-lg hover:bg-gray-100 truncate"
                      key={item.id || index}
                    >
                      {item.textContent}
                    </div>
                  ))}
                </ScrollShadow>
              </div>
            </div>
          </div>

          <div
            id={'comment-section'}
            className={`absolute bg-white z-50 w-[200px] border border-gray-200 rounded-xl shadow-sm hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 ${
              showComment ? '' : 'hidden'
            }`}
          >
            {showComment && (
              <div className={'bg-white p-4'}>
                <p className={'text-xs text-default-500'}>{selectedComment?.username}</p>
                <p className={'text-sm'}>{selectedComment?.text}</p>
                <div className={'flex flex-row justify-end'}>
                  <Button variant={'solid'} color="secondary" size={'sm'} onPress={resolveComment}>
                    <Icon icon={'lucide:check-check'} color={'#fff'} />
                  </Button>
                </div>
              </div>
            )}
          </div>
          <div className={'tiptap'}>
            <EditorContent editor={editor} content={markdown} variant={variant} />

            {editor && !isReadOnly && (
              <DragHandle
                editor={editor}
                tippyOptions={{
                  placement: 'left-start',
                  interactive: true,
                  delay: [0, 5000], // Add more time before hiding
                  hideOnClick: false,
                  appendTo: 'parent', // Ensures tooltip is appended to the relevant container
                  onMount(instance) {
                    // Prevent tooltip from hiding accidentally
                    instance.popper.style.pointerEvents = 'auto'
                  },
                  onHide(instance) {
                    // Cleanup to allow proper hiding
                    instance.popper.style.pointerEvents = ''
                  },
                  popperOptions: {
                    modifiers: [
                      {
                        name: 'preventOverflow',
                        options: {
                          boundary: 'viewport', // Ensures tooltip stays visible
                        },
                      },
                    ],
                  },
                }}
                onNodeChange={onNodeChange}
                className="w-[50px] absolute -left-[40px]"
              >
                <div className={'flex p-0 items-top z-50'}>
                  <Tooltip
                    content="Add section below"
                    color="foreground"
                    placement="bottom"
                    offset={5}
                    size="sm"
                    radius="sm"
                  >
                    <div className="flex w-[25px] cursor-pointer text-default-300" onClick={() => createNewBlock()}>
                      <Icon icon="tabler:plus" width="20" />
                    </div>
                  </Tooltip>
                  <Tooltip
                    content="Drag to move"
                    color="foreground"
                    placement="bottom"
                    offset={5}
                    size="sm"
                    radius="sm"
                  >
                    <div className="flex w-[25px] cursor-grab text-default-300">
                      <Icon icon="tabler:grip-vertical" width="20" />
                    </div>
                  </Tooltip>
                </div>
              </DragHandle>
            )}
            <Modal isOpen={isMediaModalOpen} onOpenChange={onOpenChangeMediaModal} size={'4xl'}>
              <ModalContent>
                {(onClose) => (
                  <>
                    <ModalHeader className="flex flex-col gap-1">Add Image</ModalHeader>
                    <ModalBody>
                      <div className={'flex flex-col gap-4 '}>
                        <div className={'flex flex-row justify-between gap-10'}>
                          <Input
                            autoFocus
                            ref={imageUrlInput}
                            variant="faded"
                            labelPlacement="inside"
                            label={'Image link'}
                            placeholder="Enter your media link: e.g https://powerdocs.so/media/content.png"
                            className="w-full"
                            errorMessage={'Invalid image url'}
                            onChange={() => setLoadingError(false)}
                            isInvalid={loadingError}
                          />
                        </div>
                        <span className={'text-xs italic text-default-400'}>
                          Tip: You can make your proposal stand out by inserting rich content to visualize ideas to your
                          clients.
                        </span>
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="secondary"
                        onPress={() => {
                          if (imageUrlInput.current) {
                            setLoadingError(false)
                            setLoadingImage(true)
                            const imageUrl = imageUrlInput.current.value

                            if (!imageUrl) {
                              console.error('Image URL is empty.')
                              setLoadingImage(false)
                              return
                            }

                            // Validate the image URL
                            const img = new Image()
                            img.onload = () => {
                              if (imageToEdit?.current) {
                                imageToEdit.current.src = imageUrl
                                imageToEdit.current.alt = imageUrl
                                setImageToEdit(null)
                              } else {
                                // If valid, execute the editor commands
                                editor?.commands.setMedia({
                                  src: imageUrl,
                                  'media-type': 'img',
                                  alt: 'Something else',
                                  title: 'Something',
                                  width: '800',
                                  height: '400',
                                })
                                imageUrlInput.current.value = ''
                              }
                              setLoadingImage(false)
                              onClose()
                            }

                            img.onerror = () => {
                              // If invalid, show an error
                              console.error('Invalid image URL.')
                              setLoadingError(true)
                              setLoadingImage(false)
                            }

                            img.src = imageUrl
                          }
                        }}
                      >
                        {loadingImage ? (
                          <>
                            {'Validating image'} (<Spinner size={'sm'} />
                          </>
                        ) : (
                          'Insert Image'
                        )}
                      </Button>
                    </ModalFooter>
                  </>
                )}
              </ModalContent>
            </Modal>
            <BubbleMenu
              id={'tippy-tap'}
              editor={editor}
              className="z-0 p-1 border rounded-lg border-slate-400 bg-white"
              tippyOptions={{}}
            >
              <button
                className="rounded-md bg-white/10 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
                onClick={setIsCommentModalOpen}
              >
                <Icon icon={'lucide:message-circle-more'} color={'#1c1c1c'} />
              </button>
            </BubbleMenu>
            <Modal isOpen={isCommentModalOpen} onOpenChange={commentModalOpenChange} size={'4xl'}>
              <ModalContent>
                {(onClose) => (
                  <>
                    <ModalHeader className="flex flex-col gap-1">Add New Comment</ModalHeader>
                    <ModalBody>
                      <div className={'flex flex-col gap-4 '}>
                        <div className={'flex flex-col justify-between gap-4'}>
                          {showDisplayName && (
                            <Input
                              autoFocus
                              variant="bordered"
                              labelPlacement="outside"
                              label={'Display Name'}
                              placeholder="Your display name e.g John Doe"
                              className="w-full"
                              onChange={(e) => setDisplayName(e.target.value)}
                            />
                          )}

                          <Textarea
                            variant={'bordered'}
                            label={'Content'}
                            labelPlacement="outside"
                            placeholder={'Insert your comment here. e.g Make sure to make this part smoother,..'}
                            onChange={(e) => setCommentText(e.target.value)}
                            minRows={5}
                            maxRows={5}
                          />
                        </div>
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="secondary"
                        onPress={() => {
                          addNewComment()
                        }}
                      >
                        Insert Comment
                      </Button>
                    </ModalFooter>
                  </>
                )}
              </ModalContent>
            </Modal>
            <Modal isOpen={isNameModalOpen} onOpenChange={onNameModalOpenChange} size={'4xl'}>
              <ModalContent>
                {(onClose) => (
                  <>
                    <ModalHeader className="flex flex-col gap-1">Setup Your Name</ModalHeader>
                    <ModalBody>
                      <div className={'flex flex-col gap-4 '}>
                        <div className={'flex flex-col justify-between gap-4'}>
                          <Input
                            autoFocus
                            variant="bordered"
                            labelPlacement="outside"
                            label={'Display Name'}
                            placeholder="Your display name e.g John Doe"
                            className="w-full"
                            onChange={(e) => setModalName(e.target.value)}
                          />
                        </div>
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="secondary"
                        onPress={() => {
                          setDisplayName(modalName)
                          Cookie.set('displayName', modalName)
                          onClose()
                        }}
                      >
                        Save
                      </Button>
                    </ModalFooter>
                  </>
                )}
              </ModalContent>
            </Modal>
          </div>
        </div>
      </>
    )
  },
)
