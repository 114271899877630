import { useState } from 'react'
import { Button, Divider, Tooltip } from '@nextui-org/react'
import { Icon } from '@iconify/react'
import { motion } from 'framer-motion'
import DocSidebarContentDesign from 'src/pages/powerdoc-view/DocSidebarContentDesign'
import DocSidebarContentAIAssistant from 'src/pages/powerdoc-view/DocSidebarContentAIAssistant'
import DocSidebarContentShare from 'src/pages/powerdoc-view/DocSidebarContentShare'

import Mixpanel from 'src/lib/mixpanel'
import DocSidebarComments from 'src/pages/powerdoc-view/DocSidebarComments'

export default function DocSidebar({
  variant,
  isPreview,
  downloadPdf,
  editor,
  doc,
  docDesignSettings,
  setDocDesignSettings,
  askAI,
  setLoading,
  onResolveComment,
}) {
  const [isLeftSidebarOpen, setLeftSidebarOpen] = useState(false)
  const [currentSidebarContent, setCurrentSidebarContent] = useState('design')

  const handleCloseSidebar = () => {
    setLeftSidebarOpen(false)
  }

  const handleSidebarToggle = (content: string) => {
    setLeftSidebarOpen((prev) => !(prev && content === currentSidebarContent))
    setCurrentSidebarContent(content)
  }
  return (
    <>
      {/* Left Doc Sidebar - Content */}
      {isLeftSidebarOpen && (
        <motion.div
          key={currentSidebarContent}
          initial={{ opacity: 0, x: 10 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -10 }}
          transition={{ duration: 0.2 }}
          className="absolute top-[100px] bottom-[60px] right-[140px] w-[300px] h-[calc(100vh-160px)] bg-white border rounded-xl p-5 z-40 overflow-y-scroll"
        >
          {currentSidebarContent === 'design' && (
            <DocSidebarContentDesign
              onClose={handleCloseSidebar}
              docDesignSettings={docDesignSettings}
              setDocDesignSettings={setDocDesignSettings}
            />
          )}
          {currentSidebarContent === 'assistant' && (
            <DocSidebarContentAIAssistant
              onClose={handleCloseSidebar}
              doc={doc}
              askAI={askAI}
              setLoading={setLoading}
              variant={variant}
            />
          )}
          {currentSidebarContent === 'share' && (
            <DocSidebarContentShare onClose={handleCloseSidebar} doc={doc} variant={variant} />
          )}
          {currentSidebarContent === 'comments' && (
            <DocSidebarComments
              onClose={handleCloseSidebar}
              doc={doc}
              onResolveClick={onResolveComment}
              variant={variant}
            />
          )}
        </motion.div>
      )}

      {/* Right Doc Sidebar - Toggles */}
      <div className="fixed top-[100px] bottom-[60px] right-[30px] w-[100px] max-w-[100px] h-[calc(100vh-160px)] bg-white border-1 border-gray-200 flex flex-col items-center py-4 px-2 rounded-2xl z-40 gap-6">
        {/* Top Section */}
        <div className="flex flex-col items-center gap-4 w-full">
          <div className="flex gap-2">
            <Button
              onPress={() => {
                Mixpanel.track('Undo button clicked', {
                  button_name: 'Undo',
                  page_name: window.location.pathname,
                  location: 'Document Sidebar',
                })
                editor?.commands?.undo()
              }}
              variant="light"
              isIconOnly
              isDisabled={!editor}
              size="sm"
            >
              <Icon icon="ic:round-undo" className="text-gray-400 text-2xl" />
            </Button>
            <Divider orientation="vertical" className="w-[3px]" />
            <Button
              onPress={() => {
                Mixpanel.track('Redo button clicked', {
                  button_name: 'Redo',
                  page_name: window.location.pathname,
                  location: 'Document Sidebar',
                })
                editor?.commands?.redo()
              }}
              variant="light"
              isIconOnly
              isDisabled={!editor}
              size="sm"
            >
              <Icon icon="ic:round-redo" className="text-gray-400 text-2xl" />
            </Button>
          </div>
          <Divider className="h-[3px]" />
          <p className="text-xs text-gray-500 tracking-widest text-center">AI</p>
          <Tooltip content="AI Assistant" color="foreground" showArrow={true} placement="left">
            <Button
              onPress={() => {
                Mixpanel.track('AI Assistant button clicked', {
                  button_name: 'AI Assistant',
                  page_name: window.location.pathname,
                  location: 'Document Sidebar',
                })
                handleSidebarToggle('assistant')
              }}
              variant="light"
              isIconOnly
            >
              <Icon icon="lucide:sparkles" className="text-secondary" width="30" />
            </Button>
          </Tooltip>
        </div>

        {/* Middle Section */}
        <div className="flex flex-col items-center gap-4 w-full">
          <Divider className="h-[3px]" />
          <p className="text-xs text-gray-500 tracking-widest text-center">LAYOUT</p>
          <Tooltip content="Design & Font" color="foreground" showArrow={true} placement="left">
            <Button
              onPress={() => {
                Mixpanel.track('Design & Font button clicked', {
                  button_name: 'Design & Font',
                  page_name: window.location.pathname,
                  location: 'Document Sidebar',
                })
                handleSidebarToggle('design')
              }}
              variant="light"
              isIconOnly
            >
              <Icon icon="lucide:baseline" className="text-secondary" width="30" />
            </Button>
          </Tooltip>
        </div>

        {/* Bottom Section */}
        <div className="flex flex-col items-center gap-4 w-full">
          <Divider className="h-[3px]" />
          <p className="text-xs text-gray-500 tracking-widest text-center">ACTIONS</p>
          <Tooltip content="Comments" color="foreground" showArrow={true} placement="left">
            <Button
              onPress={() => {
                Mixpanel.track('Comments button clicked', {
                  button_name: 'Comments',
                  page_name: window.location.pathname,
                  location: 'Document Sidebar',
                })
                handleSidebarToggle('comments')
              }}
              variant="light"
              isIconOnly
            >
              <Icon icon="lucide:messages-square" className="text-secondary" width="30" />
            </Button>
          </Tooltip>
          <Tooltip content="Download" color="foreground" showArrow={true} placement="left">
            <Button
              onPress={() => {
                Mixpanel.track('Download document button clicked', {
                  button_name: 'Download',
                  page_name: window.location.pathname,
                  location: 'Document Sidebar',
                })
                downloadPdf()
              }}
              variant="light"
              isIconOnly
            >
              <Icon icon="lucide:download" className="text-secondary" width="30" />
            </Button>
          </Tooltip>
          <Tooltip content="Preview" color="foreground" showArrow={true} placement="left">
            <Button
              onPress={() => {
                Mixpanel.track('Preview document button clicked', {
                  button_name: 'Preview',
                  page_name: window.location.pathname,
                  location: 'Document Sidebar',
                })
                isPreview(true)
              }}
              variant="light"
              isIconOnly
            >
              <Icon icon="lucide:eye" className="text-secondary" width="30" />
            </Button>
          </Tooltip>
          <Tooltip content="Share" color="foreground" showArrow={true} placement="left">
            <Button
              onPress={() => {
                Mixpanel.track('Share document button clicked', {
                  button_name: 'Share',
                  page_name: window.location.pathname,
                  location: 'Document Sidebar',
                })
                handleSidebarToggle('share')
              }}
              variant="light"
              isIconOnly
            >
              <Icon icon="lucide:send" className="text-secondary" width="30" />
            </Button>
          </Tooltip>
        </div>
      </div>
    </>
  )
}
