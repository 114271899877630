import {ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent} from "@/components/ui/chart";
import {z} from "zod";
import {
  Drawer, DrawerClose,
  DrawerContent,
  DrawerDescription, DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger
} from "@/components/ui/drawer";
import {Button} from "@/components/ui/button";
import {Area, AreaChart, CartesianGrid, XAxis} from "recharts";
import {Separator} from "@/components/ui/separator";
import {IconTrendingUp} from "@tabler/icons-react";
import {Label} from "@/components/ui/label";
import {Input} from "@/components/ui/input";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "@/components/ui/select";
import * as React from "react";
import {schema} from "@/pages/powerdoc-project-view";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";

const chartData = [
  { month: "January", desktop: 186, mobile: 80 },
  { month: "February", desktop: 305, mobile: 200 },
  { month: "March", desktop: 237, mobile: 120 },
  { month: "April", desktop: 73, mobile: 190 },
  { month: "May", desktop: 209, mobile: 130 },
  { month: "June", desktop: 214, mobile: 140 },
]

const chartConfig = {
  desktop: {
    label: "Desktop",
    color: "var(--primary)",
  },
  mobile: {
    label: "Mobile",
    color: "var(--primary)",
  },
} satisfies ChartConfig

export function TableCellViewer({ item }: { item: z.infer<typeof schema> }) {
  const isMobile = true
  const [url, setUrl] = useState<string>('')
  const params = useParams()
  useEffect(() => {
    if (item) {
      if (item.proposalId) {
        setUrl(`/client/${params.clientUUID}/document/${params.documentUUID}/proposal`)
      } else if (item.quotationId) {
        setUrl(`/client/${params.clientUUID}/document/${params.documentUUID}/quotation`)
      } else {
        setUrl(`/client/${params.clientUUID}/document/${params.documentUUID}/offer`)
      }
    }
  }, [item])
  return (
    <Button variant="link" className="text-foreground w-fit px-0 text-left" onClick={() => {
      navigate(url+`?section=${item.uuid}`)
    }}>
      {item.title}
    </Button>
  )
}
