import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardProps,
  Link,
  ScrollShadow,
  Spinner,
} from '@nextui-org/react'
import { useWorkspaceContextData } from 'src/context/workspace'
import { useNavigate, useParams } from 'react-router-dom'
import { Icon } from '@iconify/react'
import dayjs from 'dayjs'
import ClientDocuments from './ClientDocuments'
import api from 'src/lib/api'
import toast from 'react-hot-toast'
import Mixpanel from 'src/lib/mixpanel'

export default function ClientPage(props: CardProps) {
  const { clientsList } = useWorkspaceContextData()
  const params = useParams()
  const [activeClient, setActiveClient] = useState<any>({})

  useEffect(() => {
    if (params?.clientUUID) {
      const client = clientsList.filter((c) => c.uuid === params.clientUUID).pop()
      setActiveClient(client)
    }
  }, [clientsList, params])
  const navigate = useNavigate()
  const [sendingEmail, setSendingEmail] = useState<boolean>(false)
  const resendVerification = async () => {
    if (!sendingEmail) {
      setSendingEmail(true)
      const res = await api.post('/auth/resend')
      setSendingEmail(false)
      toast.success('Verification email has been sent successfully')
    }
  }
  const [user, setUser] = useState(null)
  const loadUser = () => {
    const userString = localStorage.getItem('user')
    if (userString) {
      setUser(JSON.parse(userString))
    }
    api
      .get('/agencies/profile')
      .then((res) => {
        localStorage.setItem('user', JSON.stringify(res.data))
        setUser(res.data)
      })
      .catch((e) => {
        console.log('Error loading current profile')
      })
  }
  useEffect(() => {
    loadUser()
  }, [])
  return (
    <>
      <div className="flex flex-col w-full max-w-screen-xl min-h-screen p-3 overflow-y-auto">
        <div className="flex w-full pt-8 pb-6 px-2 2xl:px-8">
          <header className="flex w-full justify-between">
            <div className="flex flex-col">
              <h1 className="text-xl font-bold text-default-900 lg:text-3xl">{activeClient?.name}</h1>
            </div>
          </header>
        </div>

        {user && !user?.isActive && (
          <div className={'w-full px-2 2xl:px-8'}>
            <div className={'flex w-full p-2 mb-3 rounded-xl bg-warning-100'}>
              <p className={'text-sm flex flex-row gap-1 flex-1 '}>
                {'⚠️ You need to verify your account in order to use this feature.'}
                <p className={'cursor-pointer underline'} onClick={resendVerification}>
                  Click here to resend verification email.
                </p>
              </p>
              {sendingEmail && <Spinner size={'sm'} />}
            </div>
          </div>
        )}
        <div className="flex flex-col px-2 2xl:px-8">
          <div className="flex items-center gap-1">
            <p className="text-2xl text-default-800 font-medium pb-4">Recently worked on</p>
          </div>
          <div className="overflow-x-auto relative max-w-full">
            {(!Array.isArray(activeClient?.powerdocs) || activeClient?.powerdocs.length == 0) && (
              <>
                <p className="text-default-600 font-medium pb-1">No projects yet.</p>
                <Button
                  href="/create"
                  as={Link}
                  color="secondary"
                  onPress={() => {
                    Mixpanel.track('Create first project clicked', {
                      location: 'home',
                      context: 'No projects available',
                    })
                  }}
                >
                  Create your first project
                </Button>
              </>
            )}
            {Array.isArray(activeClient?.powerdocs) && activeClient?.powerdocs.length != 0 && (
              <ScrollShadow
                orientation="horizontal"
                size={50}
                hideScrollBar
                className="flex w-full max-w-full snap-x justify-start"
              >
                <div className="grid grid-flow-col auto-cols-max gap-4 w-0 pt-4 pb-4 pl-4">
                  {activeClient?.powerdocs?.map((powerdoc, index) => (
                    <Card
                      isPressable
                      onPress={() =>
                        navigate(
                          `/client/${activeClient.name?.replace(/ /g, '-') ?? activeClient?.uuid}/document/${
                            powerdoc?.uuid
                          }`,
                        )
                      }
                      className="py-1 snap-start"
                    >
                      <CardHeader className="pt-2 pb-1 flex-col items-start">
                        <Icon icon="lucide:file-text" width="18" />
                      </CardHeader>
                      <CardBody className="overflow-visible pt-1 pb-2 w-[200px]">
                        <h4 className="font-medium text-medium line-clamp-2 leading-5">
                          {powerdoc?.title?.length > 0 ? powerdoc?.title : 'Untitled document'}
                        </h4>
                      </CardBody>
                      <CardFooter className="text-default-500 py-2">
                        <Icon icon="lucide:history" />
                        <small className="ml-1 truncate">{dayjs(powerdoc?.updatedAt).fromNow()}</small>
                      </CardFooter>
                    </Card>
                  ))}
                </div>
              </ScrollShadow>
            )}
          </div>
        </div>
        <ClientDocuments activeClient={activeClient} />
      </div>
    </>
  )
}
