import React, { useEffect, useState } from 'react'
import { Button, cn, Input } from '@nextui-org/react'
import { Icon } from '@iconify/react'

export default function QuestionsContainer({
  questions,
  onQuestionAnswer,
}: {
  questions: IQuestion[]
  onQuestionAnswer?: (answers: string[], index: number) => Promise<void>
}) {
  const [currentQuestionIndex, setCurrentQuestionIndex] = React.useState(0)
  const [hidden, setHidden] = React.useState(false)
  const currentQuestion = questions?.[currentQuestionIndex]
  const [currentAnswer, setCurrentAnswer] = useState<string[]>([])
  const [textResponse, setTextResponse] = useState<string>('')
  console.log(questions?.[currentQuestionIndex])
  useEffect(() => {
    if (questions?.[currentQuestionIndex]) {
      setCurrentAnswer(
        typeof questions?.[currentQuestionIndex]?.userResponse === 'object'
          ? questions?.[currentQuestionIndex]?.userResponse
          : [],
      )
    }
  }, [currentQuestionIndex])
  const answerQuestion = async () => {
    const copyAnswers = [...currentAnswer]
    if (textResponse?.length > 0) {
      copyAnswers.push(textResponse)
    }
    onQuestionAnswer?.(copyAnswers, currentQuestion.index)
  }
  const toggleAnswer = (response: string, isSelected: boolean) => {
    setCurrentAnswer((prev: string[]) => {
      if (isSelected) {
        const index = prev.indexOf(response)
        const copy = [...prev]
        copy.splice(index, 1)
        return copy
      } else {
        return [...prev, response]
      }
    })
  }
  return (
    <div
      className={cn(
        'relative w-full border-1 border-success-500 p-4 rounded-xl flex flex-col gap-2',
        hidden ? 'hidden' : '',
      )}
    >
      <div className={'absolute top-4 right-4 cursor-pointer p-2'} onClick={() => setHidden(true)}>
        <span className={'text-sucess-500 font-bold'}>
          <Icon icon={'lucide:x'} />
        </span>
      </div>
      <span className={'text-xl font-medium text-success-500 text-black'}>{currentQuestion?.section}</span>
      <span className={'text-sm'}>{currentQuestion?.question}</span>
      <div className={'gap-2 flex flex-wrap'}>
        {currentQuestion?.responses?.map((response) => {
          const isSelected = currentAnswer.indexOf(response) > -1
          return (
            <Button
              className={cn('w-fit', currentAnswer.indexOf(response) > -1 ? '' : '')}
              variant={isSelected ? 'solid' : 'bordered'}
              onClick={() => {
                toggleAnswer(response, isSelected)
              }}
            >
              <span className={'w-full text-xs'}>{response}</span>
            </Button>
          )
        })}
        <Input
          placeholder={'Enter your answer'}
          variant={'bordered'}
          className={'w-fit'}
          onChange={(e) => setTextResponse(e.target.value)}
        />
      </div>
      <div className={'flex flex-row justify-between'}>
        <div className={'flex flex-row items-center gap-1'}>
          <Button variant={'light'}>
            <Icon icon={'lucide:chevron-left'} />
          </Button>
          <span className={'text-md font-bold text-success-500'}>
            {currentQuestionIndex + 1}/{questions.length}
          </span>
          <Button variant={'light'}>
            <Icon icon={'lucide:chevron-right'} />
          </Button>
        </div>
        <Button
          className={'w-fit'}
          variant={'solid'}
          color={'secondary'}
          isDisabled={currentAnswer?.length === 0}
          onClick={answerQuestion}
        >
          <span className={'w-full text-xs'}>{'Add to document'}</span>
        </Button>
      </div>
    </div>
  )
}

interface IQuestion {
  chapter: string | null
  section: string | null
  question: string
  responses: string[]
  index: number
  userResponse: string[]
}
