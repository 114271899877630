import * as React from 'react'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'src/components/ui/dropdown-menu'
import { z } from 'zod'
export const schema = z.object({
  id: z.number(),
  title: z.string(),
  dueDate: z.string(),
  status: z.string(),
  editDate: z.string(),
  permissions: z.array(
    z.object({
      id: z.number(),
      user: z.object({
        id: z.number(),
        firstname: z.string(),
        lastname: z.string(),
        email: z.string(),
      }),
    }),
  ),
})
import DataTable from '@/pages/powerdoc-project-view/components/DataTable'
import api from '@/lib/api'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  BreadcrumbItem,
  Breadcrumbs,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spinner,
  useDisclosure,
} from '@nextui-org/react'
import { Icon } from '@iconify/react'
import { useWorkspaceContextData } from '@/context/workspace'
import Mixpanel from '@/lib/mixpanel'
import { PowerDocsEventsName } from '@/types/SocketEvents'
import { useSocketContextData } from '@/context/socket'
import dayjs from 'dayjs'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { Calendar } from '@/components/ui/calendar'
import { format } from 'date-fns'
interface MemberUser {
  id: number
  firstname: string
  lastname: string
  email: string
}
interface Member {
  id: number
  role: 'OWNER' | 'EDITOR' | 'VIEWER'
  user: MemberUser
}

export default function PowerDocProjectView() {
  const [doc, setDoc] = useState<any>({})
  const [initialized, setInitialized] = useState<boolean>(false)
  const [variant, setVariant] = useState<'PROPOSAL' | 'QUOTATION' | 'OFFER'>('PROPOSAL')
  const [sections, setSections] = useState<any[]>([])
  const { documentUUID } = useParams()
  const [members, setMembers] = useState<MemberUser[]>([])
  const [loading, setLoading] = useState(false)
  const loadDocument = async () => {
    const res = await api.get(`/powerdocs/${documentUUID}`)
    setDoc(res.data)
    if (res.data?.offerId) {
      setVariant('OFFER')
    } else if (res.data?.quotationId) {
      setVariant('QUOTATION')
    }
    setInitialized(true)
  }

  const { activeWorkspace } = useWorkspaceContextData()

  const loadMembers = async (workspaceUUID: string) => {
    setLoading(true)
    const res = await api.get(`/workspaces/${workspaceUUID}/members`)
    const membersList = ((res.data?.members as Member[]) ?? []).map((member) => member.user)
    for (const invitation of (res.data?.invitations as Member[]) ?? []) {
      membersList.push(invitation.user)
    }
    setMembers(membersList)
    setLoading(false)
  }
  useEffect(() => {
    if (activeWorkspace) {
      loadMembers(activeWorkspace)
    }
  }, [activeWorkspace])

  useEffect(() => {
    loadDocument()
  }, [])
  useEffect(() => {
    if (doc && variant) {
      setSections(doc[variant.toLowerCase()]?.sections ?? [])
    }
  }, [doc, variant])

  const navigate = useNavigate()
  const [isGenerating, setIsGenerating] = useState<boolean>(false)
  const { io } = useSocketContextData()
  const params = useParams()
  const generateQuotation = async () => {
    Mixpanel.track('Generate Quotation clicked', {
      document_id: doc.uuid,
      location: 'Doc Switcher',
    })
    setIsGenerating(true)
    io.emitWithAck(PowerDocsEventsName.GENERATE_QUOTATION, {
      token: localStorage.getItem('accessToken'),
      workspaceUUID: activeWorkspace,
      powerDocUUID: params.documentUUID,
    })
  }
  const generateProposal = async () => {
    Mixpanel.track('Generate Proposal clicked', {
      document_id: params.documentUUID,
      location: 'Doc Switcher',
    })
    setIsGenerating(true)
    io.emitWithAck(PowerDocsEventsName.GENERATE_PROPOSAL, {
      token: localStorage.getItem('accessToken'),
      workspaceUUID: activeWorkspace,
      powerDocUUID: params.documentUUID,
    })
  }
  const generateOffer = async () => {
    Mixpanel.track('Generate Offer clicked', {
      document_id: params.documentUUID,
      location: 'Sections Tables',
    })
    setIsGenerating(true)
    io.emitWithAck(PowerDocsEventsName.GENERATE_OFFER, {
      token: localStorage.getItem('accessToken'),
      workspaceUUID: activeWorkspace,
      powerDocUUID: params.documentUUID,
    })
  }
  const onCreateVariantClick = () => {
    switch (variant.toUpperCase()) {
      case 'PROPOSAL':
        generateProposal()
        break
      case 'OFFER':
        generateOffer()
        break
      case 'QUOTATION':
        generateQuotation()
        break
    }
  }
  useEffect(() => {
    if (io) {
      io.on(PowerDocsEventsName.QUOTATION_GENERATED, () => {
        navigate(`/client/${params.clientUUID}/document/${params.documentUUID}/quotation`)
      })
      io.on(PowerDocsEventsName.QUOTATION_DONE, () => {
        setIsGenerating(false)
      })
      io.on(PowerDocsEventsName.OFFER_GENERATED, () => {
        navigate(`/client/${params.clientUUID}/document/${params.documentUUID}/offer`)
      })
      io.on(PowerDocsEventsName.OFFER_DONE, () => {
        setIsGenerating(false)
      })
      io.on(PowerDocsEventsName.PROPOSAL_GENERATED, () => {
        navigate(`/client/${params.clientUUID}/document/${params.documentUUID}/proposal`)
      })
      io.on(PowerDocsEventsName.PROPOSAL_DONE, () => {
        setIsGenerating(false)
      })
    }
  }, [io])

  const { isOpen: isNewSectionOpen, onOpenChange: onNewSectionOpenChange } = useDisclosure()
  const addSectionClicked = () => {
    onNewSectionOpenChange(true)
  }
  const [sectionName, setSectionName] = useState<string>('')
  const [isCreating, setIsCreating] = useState<boolean>(false)
  const createNewSection = async () => {
    setIsCreating(true)
    const res = await io.emitWithAck(PowerDocsEventsName.CREATE_NEW_SECTION, {
      variant,
      documentUUID: params.documentUUID,
      token: localStorage.getItem('accessToken'),
      title: sectionName,
    })
    console.log(res)
    if (res?.[variant.toLowerCase()]?.sections) {
      setSections(res[variant.toLowerCase()]?.sections ?? [])
    }
    setIsCreating(false)
    onNewSectionOpenChange(false)
  }
  useEffect(() => {
    if (!isNewSectionOpen) {
      setSectionName('')
    }
  }, [isNewSectionOpen])
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false)
  const [dueDate, setDueDate] = useState<string>('')
  useEffect(() => {
    if (doc) {
      setDueDate(doc?.[variant?.toLowerCase()]?.dueDate ?? '')
    }
  }, [variant, doc])
  const updateDueDate = (value: Date) => {
    setDueDate(value as unknown as string)
    setCalendarOpen(false)
    setDoc((prev: any) => ({
      ...prev,
      [variant.toLowerCase()]: {
        ...prev[variant.toLowerCase()],
        dueDate: value,
      },
    }))
    io.emit(PowerDocsEventsName.UPDATE_POWERDOC_DUE_DATE, {
      dueDate: value,
      documentUUID: doc.uuid,
      token: localStorage.getItem('accessToken'),
      variant,
    })
  }
  const [currentStatus, setCurrentStatus] = useState<
    'DRAFT' | 'PENDING_APPROVAL' | 'APPROVED' | 'SENT' | 'SIGNED' | 'REJECTED' | 'ARCHIVED'
  >('DRAFT')
  useEffect(() => {
    if (doc && variant) {
      setCurrentStatus(doc?.[variant?.toLowerCase()]?.status ?? 'DRAFT')
    }
  }, [doc, variant])
  const renderStatus = () => {
    const bgColor = '#fff'
    const borderColor = '#1c1a1f'
    const updateStatus = (
      status: 'DRAFT' | 'PENDING_APPROVAL' | 'APPROVED' | 'SENT' | 'SIGNED' | 'REJECTED' | 'ARCHIVED',
    ) => {
      setDoc((prev: any) => ({
        ...prev,
        [variant.toLowerCase()]: {
          ...prev[variant.toLowerCase()],
          status,
        },
      }))
      io.emit(PowerDocsEventsName.UPDATE_POWERDOC_STATUS, {
        documentUUID: doc.uuid,
        token: localStorage.getItem('accessToken'),
        variant,
        status,
      })
    }
    return (
      <DropdownMenu>
        <DropdownMenuTrigger asChild className={'focus:outline-none'}>
          <div className={'border-1 border-default rounded-md pt-1 pb-1 pl-4 pr-4 flex flex-row gap-4 cursor-pointer'}>
            <span className={'text-xs capitalize'}>{currentStatus.split('_').join(' ').toLowerCase()}</span>
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-70 bg-white cursoir-pointer">
          {['DRAFT', 'PENDING_APPROVAL', 'APPROVED', 'SENT', 'SIGNED', 'REJECTED', 'ARCHIVED'].map((status) => (
            <DropdownMenuItem
              key={status}
              onSelect={(e) => {
                updateStatus(status)
              }}
              className={'capitalize'}
            >
              {status.split('_').join(' ').toLowerCase()}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    )
  }
  return (
    <div className="flex flex-col w-full max-w-screen-xl h-screen p-3">
      <Breadcrumbs className={'pl-2 pt-4'}>
        <BreadcrumbItem onClick={() => navigate('/')}>
          <Icon icon={'lucide:home'} /> Project Pipeline
        </BreadcrumbItem>
        <BreadcrumbItem onClick={() => navigate('/settings')}>Project Overview</BreadcrumbItem>
        <BreadcrumbItem className={'capitalize'}>{variant.toLowerCase()}</BreadcrumbItem>
      </Breadcrumbs>
      <div className="flex w-full pt-8 pb-6 px-2 2xl:px-8">
        <header className="flex flex-col w-full gap-4">
          <div className="w-fit flex flex-row items-center gap-2">
            <div
              className={'w-fit flex flex-row items-center gap-2 cursor-pointer'}
              onClick={() => navigate('/powerdocs')}
            >
              <Icon icon={'lucide:square-arrow-left'} width={25} color={'#71717a'} />
              <h1 className="text-3xl font-bold text-default-900 capitalize">{doc?.title}</h1>
            </div>
            {renderStatus()}
          </div>
          <div className={'flex flex-row gap-4'}>
            <div className={'border-1 border-default rounded-md pt-1 pb-1 pl-4 pr-4 flex flex-row gap-4'}>
              <Icon icon={'lucide:handshake'} width={16} color={'#71717a'} />
              <span className={'text-xs'}>{doc.client?.name ?? 'No Client Selected'}</span>
            </div>
            <Popover open={calendarOpen} onOpenChange={setCalendarOpen}>
              <PopoverTrigger asChild>
                <div
                  className={
                    'border-1 border-default rounded-md pt-1 pb-1 pl-4 pr-4 flex flex-row gap-4 cursor-pointer'
                  }
                  onClick={() => setCalendarOpen(true)}
                >
                  <Icon icon={'lucide:calendar-days'} width={16} color={'#71717a'} />
                  <span className={'text-xs'}>
                    {dueDate ? `${format(dueDate, 'PPP')} | ${dayjs(dueDate).fromNow()}.` : 'No Due date specified yet'}
                  </span>
                </div>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0 bg-white" align="start">
                <Calendar
                  initialFocus
                  mode="single"
                  selected={dueDate as unknown as Date}
                  //@ts-ignore
                  onSelect={updateDueDate}
                />
              </PopoverContent>
            </Popover>
          </div>
        </header>
      </div>
      <div className="flex w-full pb-2 max-w-full max-h-full overflow-y-auto">
        <DataTable
          variant={variant.toLowerCase()}
          data={sections}
          key={`${sections.length}_sections`}
          members={members}
          isLoading={loading || !initialized}
          setVariant={setVariant}
          onCreateVariantClick={onCreateVariantClick}
          isGenerating={isGenerating}
          addSectionClicked={addSectionClicked}
        />
      </div>
      <Modal isOpen={isNewSectionOpen} onOpenChange={onNewSectionOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1"></ModalHeader>
              <ModalBody>
                <div className="flex flex-col grow gap-7">
                  <div className="flex flex-col items-center">
                    <p className="text-2xl font-semibold">Create New Section</p>
                    <p className="text-base text-center">Add new empty section to your document.</p>
                  </div>
                  <div className="flex flex-col gap-3 items-center">
                    <Input
                      isRequired
                      type="text"
                      label="Section name"
                      placeholder="e.g. Executive summary"
                      className="max-w-xs"
                      value={sectionName}
                      onChange={(e) => setSectionName(e.target.value)}
                    />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Cancel
                </Button>
                <Button
                  color="secondary"
                  onPress={createNewSection}
                  isDisabled={sectionName.length === 0 || isCreating}
                >
                  {isCreating ? 'Creating ' : 'Create section '}
                  {isCreating && <Spinner size={'sm'} />}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  )
}
