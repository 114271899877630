import { Heading } from '@tiptap/extension-heading'
import { mergeAttributes, Extension } from '@tiptap/core'
import { Plugin, PluginKey } from '@tiptap/pm/state'
import { Decoration, DecorationSet } from '@tiptap/pm/view'
import { v4 as uuidv4 } from 'uuid'

export const CustomHeading = Heading.extend({
  addAttributes() {
    return {
      level: {
        default: 2,
        parseHTML: (element) => Number(element.tagName[1]),
      },
      'data-permission-id': {
        default: null,
        parseHTML: (element) =>
          element.getAttribute('data-permission-id') && element.getAttribute('data-permission-id') !== 'null'
            ? element.getAttribute('data-permission-id')
            : uuidv4({}, undefined, Math.random()),
        renderHTML: (attributes) => ({
          'data-permission-id':
            attributes['data-permission-id'] && attributes['data-permission-id'] !== 'null'
              ? attributes['data-permission-id']
              : uuidv4({}, undefined, Math.random()),
        }),
      },
    }
  },

  renderHTML({ node, HTMLAttributes }) {
    return [`h${node.attrs.level}`, mergeAttributes(HTMLAttributes), 0]
  },
  addStorage() {
    return {
      markdown: {
        serialize(state, node) {
          const startMarker =
            '<h2 data-permission-id="' +
            (node?.attrs?.['data-permission-id'] && node?.attrs?.['data-permission-id'] !== 'null'
              ? node?.attrs?.['data-permission-id']
              : uuidv4({}, undefined, Math.random())) +
            '">'
          const endMarker = '</h2>'
          const plainTextContent = node.attrs?.content || node.textContent || ''
          state.write(`${startMarker}${plainTextContent}${endMarker}`)
          state.closeBlock(node)
        },
      },
    }
  },
})

export const PermissionFilter = (currentUserWithPermission: any) =>
  Extension.create({
    name: 'PermissionFilter',

    addProseMirrorPlugins() {
      return [
        new Plugin({
          key: new PluginKey('PermissionFilter'),

          props: {
            decorations: (state) => {
              console.log(currentUserWithPermission)
              if (currentUserWithPermission?.canEditTheWholeDocument) {
                return
              }
              const { doc } = state
              const decorations: Decoration[] = []
              let isEditable = false
              doc.descendants((node, pos) => {
                if (node.type?.name === 'heading' && node?.attrs?.level === 2) {
                  const sectionId = node.attrs['data-permission-id']
                  isEditable = currentUserWithPermission?.sections?.includes?.(sectionId)
                }
                if (isEditable) {
                  console.log('Section : ', node.attrs, 'is editable')
                  // Remove 'non-editable' and ensure the section is editable
                  decorations.push(
                    Decoration.node(pos, pos + node.nodeSize, {
                      class: 'editable', // Apply correct class
                      contenteditable: true,
                    }),
                  )
                } else {
                  // Apply 'non-editable' class for unauthorized sections
                  decorations.push(
                    Decoration.node(pos, pos + node.nodeSize, {
                      class: 'non-editable',
                      contenteditable: false,
                    }),
                  )
                }
              })

              return DecorationSet.create(doc, decorations)
            },
          },
          filterTransaction(transaction, state) {
            return true // Allow edit if no unauthorized sections are modified
          },
        }),
      ]
    },
  })
