import React, { useCallback, useEffect, useState } from 'react'
import { useSocketContextData } from 'src/context/socket'
import { Button, Tooltip, Divider } from '@nextui-org/react'
import { useDropzone } from 'react-dropzone'
import api from 'src/lib/api'
import { Icon } from '@iconify/react'
import { motion } from 'framer-motion'
import CoverPageContentDetails from 'src/pages/powerdoc-view/extensions/CoverPageContentDetails'
import CoverPageContentDesign from 'src/pages/powerdoc-view/extensions/CoverPageContentDesign'

import Mixpanel from 'src/lib/mixpanel'

export default function CoverPage({ doc, isVisible, close }: any) {
  const [title, setTitle] = useState<string>(doc?.title)
  const [documentDate, setDocumentDate] = useState<string>(doc?.documentDate)
  const [createdBy, setCreatedBy] = useState<string>(doc?.createdBy)
  const [proposedTo, setProposedTo] = useState<string>(doc?.proposedTo)
  const [logo, setLogo] = useState<string>(doc?.coverLogo)
  const [file, setFile] = useState<File>()
  const [isLeftSidebarOpen, setLeftSidebarOpen] = useState(true)
  const [currentSidebarContent, setCurrentSidebarContent] = useState('details')
  const [coverPageDesign, setCoverPageDesign] = useState<string>(doc?.coverPageDesign || 'default')

  const handleCloseSidebar = () => {
    setLeftSidebarOpen(false)
  }

  const handleSidebarToggle = (content) => {
    setLeftSidebarOpen(true)
    setCurrentSidebarContent(content)
  }

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        close?.()
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [close])
  const { io } = useSocketContextData()
  const updateCoverPageDetails = async () => {
    const res = await io.emitWithAck('update_document', {
      token: localStorage.getItem('accessToken'),
      documentUUID: doc.uuid,
      title,
      documentDate,
      createdBy,
      proposedTo,
    })
  }
  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    if (acceptedFiles[0]) {
      const preview = URL.createObjectURL(acceptedFiles[0])
      setLogo(preview)
      const formData = new FormData()
      formData.append('file', acceptedFiles[0])
      const res = await api.post(`/urs/${doc?.uuid}/logo`, formData)
    }
  }, [])

  const deleteFile = async () => {
    setLogo('')
    const formData = new FormData()
    formData.append('logo', '')
    const res = await api.post(`/urs/${doc?.uuid}/logo`, formData)
  }
  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: 'image/*', // Accept specific file types if needed
    maxFiles: 1, // Limit to one file at a time
  })

  const coverPageDetails = {
    documentDate,
    setDocumentDate,
    title,
    setTitle,
    createdBy,
    setCreatedBy,
    proposedTo,
    setProposedTo,
    updateCoverPageDetails,
    logo,
    open,
    deleteFile,
    getRootProps,
    getInputProps,
  }

  const coverPageDesignProps = {
    coverPageDesign,
    setCoverPageDesign,
  }

  return (
    isVisible && (
      <div className="absolute inset-0 flex justify-center z-[60]">
        <div className="absolute inset-0 overflow-hidden rounded-b-lg bg-white flex flex-1">
          {/* Left sidebar */}
          <div className="fixed top-[60px] bottom-[60px] left-[30px] w-[100px] max-w-[100px] h-[calc(100vh-120px)] bg-white border-1 border-gray-200 flex flex-col items-center py-4 px-2 rounded-2xl z-40 gap-6">
            {/* Middle Section */}
            <div className="flex flex-col items-center gap-4 w-full">
              <p className="text-xs text-gray-500 tracking-widest text-center">DETAILS</p>
              <Tooltip content="Details" color="foreground" showArrow={true} placement="right">
                <Button
                  onPress={() => {
                    Mixpanel.track('Cover Page Details button clicked', {
                      button_name: 'Details',
                      page_name: window.location.pathname,
                      location: 'Cover Page Sidebar',
                    })
                    handleSidebarToggle('details')
                  }}
                  variant="light"
                  isIconOnly
                >
                  <Icon icon="lucide:clipboard-type" className="text-secondary" width="30" />
                </Button>
              </Tooltip>
            </div>

            {/* Bottom Section */}
            <div className="flex flex-col items-center gap-4 w-full">
              <Divider className="h-[3px]" />
              <p className="text-xs text-gray-500 tracking-widest text-center">DESIGN</p>
              <Tooltip content="Design" color="foreground" showArrow={true} placement="right">
                <Button
                  isDisabled
                  onPress={() => {
                    Mixpanel.track('Cover Page Design button clicked', {
                      button_name: 'Design',
                      page_name: window.location.pathname,
                      location: 'Cover Page Sidebar',
                    })
                    handleSidebarToggle('design')
                  }}
                  variant="light"
                  isIconOnly
                >
                  <Icon icon="lucide:layout-template" className="text-secondary" width="30" />
                </Button>
              </Tooltip>
            </div>
          </div>

          {/* Left sidebar content */}
          {isLeftSidebarOpen && (
            <motion.div
              key={currentSidebarContent}
              initial={{ opacity: 0, x: -10 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -10 }}
              transition={{ duration: 0.2 }}
              className="absolute top-[60px] bottom-[60px] left-[140px] w-[300px] h-[calc(100vh-120px)] bg-white border rounded-xl p-5 z-50 overflow-y-scroll"
            >
              {currentSidebarContent === 'details' && (
                <CoverPageContentDetails onClose={handleCloseSidebar} coverPageDetails={coverPageDetails} />
              )}
              {currentSidebarContent === 'design' && (
                <CoverPageContentDesign onClose={handleCloseSidebar} coverPageDesignProps={coverPageDesignProps} />
              )}
            </motion.div>
          )}

          {/* Cover page content */}
          <button className={'absolute top-10 right-10 text-black'} onClick={() => close()}>
            <Icon icon="lucide:x" width="24" />
          </button>

          <div className={'flex flex-1 justify-center items-center bg-whites pl-[440px] py-5'}>
            <div
              // className="bg-white rounded-lg p-8 w-[210mm] h-[297mm] border rounded-xl"
              className="bg-white rounded-lg p-8 border rounded-xl w-[70%] max-w-[calc(100vh*0.7)] h-full"
              style={{
                padding: '0.75in',
                // borderWidth: '0.75in',
                borderColor: 'none',
              }}
              id={'cover-page-component'}
            >
              <div className="cover-page flex flex-col h-full justify-between">
                {/* Logo Section */}
                {logo && (
                  <div className="flex justify-start w-full">
                    <img src={logo} className="w-[150px]" />
                  </div>
                )}

                {/* Title Section */}
                <div className="flex-1 flex flex-col w-full items-left justify-center">
                  <div className="text-lg">{documentDate}</div>
                  <div className="text-3xl font-bold">{title}</div>
                </div>

                {/* Footer Section - Created By & Proposed To */}
                <div className="flex flex-row w-full gap-4">
                  {/* Left Column - Created By */}
                  <div className="w-1/2 flex flex-col">
                    <div className="font-semibold text-lg">Created by</div>
                    <div className="whitespace-pre-wrap">
                      {createdBy?.split('\n').map((item, index) => (
                        <span key={index} className="block">
                          {item}
                        </span>
                      ))}
                    </div>
                  </div>

                  {/* Right Column - Proposed To */}
                  <div className="w-1/2 flex flex-col">
                    <div className="font-semibold text-lg">Prepared for</div>
                    <div className="whitespace-pre-wrap">
                      {proposedTo?.split('\n').map((item, index) => (
                        <span key={index} className="block">
                          {item}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  )
}
