import { Node, ReactNodeViewRenderer } from '@tiptap/react'
import { CoverPageComponent } from 'src/pages/powerdoc-view/extensions/CoverPageComponent'
import { mergeAttributes } from '@tiptap/core'

export const CoverPageNode = Node.create({
  name: 'cover',
  group: 'block',
  content: 'inline*',
  defining: true,

  addAttributes() {
    return {
      content: {
        default: '',
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'div[data-type="cover"]',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },
  addNodeView() {
    return ReactNodeViewRenderer(CoverPageComponent)
  },

  addStorage() {
    return {
      markdown: {
        serialize(state, node) {
          const startMarker = '@@COVER@@'
          const endMarker = '@@END_COVER@@'
          const plainTextContent =
            node.attrs?.content ||
            node.content?.content.map((child) => child.text).join('@@PARAM@@') ||
            node.textContent ||
            ''
          state.write(`${startMarker}${plainTextContent}${endMarker}`)
          state.closeBlock(node)
        },
      },
    }
  },
})
