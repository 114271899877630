export enum PowerDocsEventsName {
  CREATE_DOCUMENT = 'create_power_doc',
  GENERATE_QUOTATION = 'generate_quotation',
  GENERATE_PROPOSAL = 'generate_quotation',
  GENERATE_OFFER = 'generate_quotation',
  PROPOSAL_GENERATED = 'proposal_generated',
  PROPOSAL_CHUNK = 'proposal_chunk',
  PROPOSAL_DONE = 'proposal_done',
  QUOTATION_GENERATED = 'quotation_generated',
  QUOTATION_CHUNK = 'quotation_chunk',
  QUOTATION_DONE = 'quotation_done',
  OFFER_GENERATED = 'offer_generated',
  OFFER_CHUNK = 'offer_chunk',
  OFFER_DONE = 'offer_done',
  ASSIGN_TO_MEMBER = 'assign_section_to_member',
  UPDATE_SECTION_DUE_DATE = 'update_section_due_date',
  UPDATE_POWERDOC_DUE_DATE = 'update_powerdoc_due_date',
  UPDATE_SECTION_STATUS = 'update_section_status',
  UPDATE_POWERDOC_STATUS = 'update_powerdoc_status',
  UPDATE_POWERDOC_CONTENT = 'update_powerdoc_content',
  CREATE_NEW_SECTION = 'create_new_section',
  REORDER_POWERDOC_SECTIONS = 'reorder_sections',
}
