import { cn, Spinner } from '@nextui-org/react'

import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'
import { useSocketContextData } from 'src/context/socket'

import api, { baseURL } from 'src/lib/api'
import { TipTapEditor } from './extensions/tiptap'

import { useWorkspaceContextData } from 'src/context/workspace'
import DocumentPreview from './DocumentPreview'
import DocSidebar from './DocSidebar'

import FeedbackBar from './FeedbackBar'
import Mixpanel from 'src/lib/mixpanel'
import { Simulate } from 'react-dom/test-utils'
import load = Simulate.load
import { PowerDocsEventsName } from 'src/types/SocketEvents'

export default function PowerDocument({
  isReadOnly,
  variant,
}: {
  isReadOnly?: boolean
  variant: 'quotation' | 'proposal' | 'offer'
}) {
  const navigate = useNavigate()
  const { activeWorkspace, profile } = useWorkspaceContextData()
  const { t } = useTranslation()
  const { documentUUID } = useParams()
  const { io } = useSocketContextData()

  const [doc, setDoc] = useState<any>({})
  const [initialized, setInitizalied] = useState<boolean>(false)
  const [content, setContent] = useState<string>('')
  const [documentLoading, setDocumentLoading] = useState(false)
  const [showAIAssistant, setShowAIAssistant] = useState<boolean>(false)
  const [isPreview, setIsPreview] = useState<boolean>(false)
  const [currentActiveWorkspace, setCurrentActiveWorkspace] = useState(activeWorkspace ?? '')
  const [editorInstance, setEditorInstance] = useState(null)
  const [docDesignSettings, setDocDesignSettings] = useState({
    fontType: 'Inter',
    fontSize: 2, // 1 = small, 2 = medium, 3 = large
    sectionSpacing: 4, // between 1-7
    skipUpdate: true,
  })

  const tiptapEditorRef = useRef(null)

  const downloadPdf = () => {
    const popup = window.open(`${baseURL}/powerdocs/export/${doc.uuid}/${variant}`)
    if (popup) {
      popup?.focus()
      popup.addEventListener('unload', () => {
        popup.close()
      })
    }
  }
  const loadDocument = async () => {
    const res = await api.get(`/powerdocs/${documentUUID}`)
    setDoc(res.data)
    let content, designSetting
    switch (variant) {
      case 'quotation':
        content = res.data?.quotation?.content ?? ''
        designSetting = res.data?.quotation?.designSetting
        break
      case 'offer':
        content = res.data?.offer?.content ?? ''
        designSetting = res.data?.offer?.designSetting
        break
      case 'proposal':
        content = res.data?.proposal?.content ?? ''
        designSetting = res.data?.proposal?.designSetting
        break
    }
    setDocDesignSettings(
      designSetting ?? {
        fontType: 'Inter',
        fontSize: 2, // 1 = small, 2 = medium, 3 = large
        sectionSpacing: 4, // between 1-7
      },
    )
    if (content) {
      setContent(content)
    }
    setInitizalied(true)
  }

  const askAI = async (question: string) => {
    const currentDocument = tiptapEditorRef.current?.getMarkdown()
    const result = await io.emitWithAck('askAIAgent', {
      documentUUID,
      question,
      token: localStorage.getItem('accessToken'),
      currentDocument,
      variant,
    })

    const parts = result.split('@@URD@@')
    if (parts.length > 1) {
      setContent(parts[1] ?? content)
      loadDocument()
    }
    return parts[0]
  }

  const onQuestionAnswer = async (answers: string[], index: number) => {
    // Track interaction with Questions Picker
    const query = doc.questions[index]
    let answer_type = 'suggested'
    for (const answer of answers) {
      if (!query.responses.includes(answer)) {
        answer_type = 'user_input'
        break
      }
    }
    if (answer_type.length > 0) {
      answer_type = 'mixed'
    }
    Mixpanel.track('Questions picker used', {
      question: query.question,
      selected_answers: answers.join('\n'),
      answer_type: answer_type, // Indicates if the answer was suggested or custom-defined
      section: query.section,
      question_index: index,
    })
    setDocumentLoading(true)
    try {
      const document = tiptapEditorRef.current?.getMarkdown()
      const newURS = await io.emitWithAck('answerQuestionForDocument', {
        answers,
        index,
        document,
        ursUUID: documentUUID,
        question: query.question,
        token: localStorage.getItem('accessToken'),
      })
      const questions = doc.questions ?? []
      questions[index].userResponses = answers
      setDoc({
        ...doc,
        questions,
        document: newURS.document,
      })
      setContent(newURS.document)
      tiptapEditorRef.current?.setMarkdown(newURS.document)
      setDocumentLoading(false)
    } catch (err) {
      console.log('Error here ', err)
    }
  }

  useEffect(() => {
    if (tiptapEditorRef.current) {
      setEditorInstance(tiptapEditorRef.current)
    }
  }, [tiptapEditorRef.current])
  useEffect(() => {
    if (doc) {
      let content
      switch (variant) {
        case 'quotation':
          content = doc.quotation?.content
          break
        case 'offer':
          content = doc.offer?.content
          break
        case 'proposal':
          content = doc.proposal?.content
          break
      }
      if (content) {
        setContent(content)
      }
    }
  }, [doc, variant])

  useEffect(() => {
    loadDocument()
  }, [])

  useEffect(() => {
    if (activeWorkspace !== currentActiveWorkspace) {
      navigate('/powerdocs')
    }
  }, [activeWorkspace])

  useEffect(() => {
    if (io) {
      io.on(PowerDocsEventsName.PROPOSAL_CHUNK, (data) => {
        const documentString = data.document.split('@@QUESTIONS@@')[0].replace(/@@URD@@/g, '')
        setContent(documentString)
      })
      io.on(PowerDocsEventsName.QUOTATION_CHUNK, (data) => {
        const documentString = data.document.split('@@QUESTIONS@@')[0].replace(/@@URD@@/g, '')
        setContent(documentString)
      })
      io.on(PowerDocsEventsName.OFFER_CHUNK, (data) => {
        const documentString = data.document.split('@@QUESTIONS@@')[0].replace(/@@URD@@/g, '')
        setContent(documentString)
      })
      io.on('inject_questions', (data) => {
        const questions = data?.questions ?? []
        const newDocument = { ...doc }
        switch (variant.toUpperCase()) {
          case 'PROPOSAL':
            setDoc({
              ...doc,
              proposal: {
                ...(doc?.proposal || {}),
                questions,
              },
            })
            break
          case 'QUOTATION':
            setDoc({
              ...doc,
              quotation: {
                ...(doc?.quotation || {}),
                questions,
              },
            })
            break
          case 'OFFER':
            setDoc({
              ...doc,
              offer: {
                ...(doc?.offer || {}),
                questions,
              },
            })
            break
        }
      })

      io.on(PowerDocsEventsName.PROPOSAL_DONE, () => {
        loadDocument()
      })

      io.on(PowerDocsEventsName.QUOTATION_DONE, () => {
        loadDocument()
      })

      io.on(PowerDocsEventsName.OFFER_DONE, () => {
        loadDocument()
      })
    }
  }, [io])

  useEffect(() => {
    //@ts-ignore
    window.closeCreateModal?.()
  }, [])

  const [currentUserWithPermission, setCurrentUserWithPermission] = useState<any>(null)
  useEffect(() => {
      console.log(profile, doc)
      if (doc && profile && doc.userId && profile.id && doc.userId === profile.id && !currentUserWithPermission?.canEditTheWholeDocument) {
        setCurrentUserWithPermission({
          canEditTheWholeDocument: true,
        })
      } else if (!currentUserWithPermission){
        const permissionList = doc?.[variant.toLowerCase()]?.permissions
        const sections = permissionList?.map((permission) => permission.section?.uuid)
        console.log(sections)
        setCurrentUserWithPermission({
          canEditTheWholeDocument: false,
          sections,
        })
      }
  }, [profile, doc, variant])

  const [searchParams, setSearchParams] = useSearchParams();
  const [alreadyScrolled, setAlreadyScrolled] = useState<boolean>(false)
  useEffect(() => {
    const section = searchParams.get("section")
    if (section && !alreadyScrolled) {
      const element = document.querySelector('[data-permission-id="'+section+'"]')
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
        setAlreadyScrolled(true)
      }
    }
  }, [searchParams, doc])
  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>
      <div className="flex w-full h-screen bg-white">
        {initialized ? (
          <div className="flex w-full h-full gap-2 overflow-hidden">
            {/* Main Content Area */}
            <div className={cn('flex grow h-full justify-center gap-2')}>
              <div
                className={cn(
                  'flex flex-col w-full overflow-y-scroll no-scrollbar items-center transition-all duration-500 ease-in-out',
                )}
              >
                {/* Doc Editor */}
                <div className="flex flex-col w-full items-center">
                  <TipTapEditor
                    key={JSON.stringify({ variant, currentUserWithPermission })}
                    ref={tiptapEditorRef}
                    currentUserWithPermission={currentUserWithPermission}
                    markdown={content}
                    documentUUID={doc.uuid}
                    isReadOnly={isReadOnly}
                    doc={doc}
                    docDesignSettings={docDesignSettings}
                    onQuestionAnswer={onQuestionAnswer}
                    variant={variant}
                    setDoc={setDoc}
                  />
                </div>

                {/* Doc Feedback Bar */}
                <div className="flex w-full sticky bottom-2 justify-center z-30">
                  {(doc.score === undefined || doc.score === -1) && !isReadOnly && (
                    <FeedbackBar documentUUID={documentUUID as string} />
                  )}
                </div>

                {/* Doc Save Info */}
                {documentLoading && (
                  <div className={'fixed w-full h-full top-0 left-0 z-50'}>
                    <div
                      className={
                        'bg-black/75 backdrop-blur-sm w-full h-full justify-center items-center flex flex-col text-2xl text-white gap-3'
                      }
                    >
                      <div className={'flex flex-row gap-2 justify-center items-center'}>
                        <Spinner size={'lg'} color="secondary" />
                        <span>Adding new information to document…</span>
                      </div>
                      <span className={'text-white text-sm opacity-60 text-center'}>
                        It can take up to 30 seconds.
                        <br />
                        Please wait and keep this browser tab open.
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Doc Sidebar */}
            {!isReadOnly && (
              <div className="flex-none w-[130px]">
                <DocSidebar
                  isPreview={setIsPreview}
                  downloadPdf={downloadPdf}
                  editor={editorInstance}
                  doc={doc}
                  variant={variant}
                  setLoading={setDocumentLoading}
                  askAI={askAI}
                  docDesignSettings={docDesignSettings}
                  setDocDesignSettings={(callback) => {
                    setDocDesignSettings(callback)
                    io.emitWithAck('update_design', {
                      designSetting: callback(docDesignSettings),
                      token: localStorage.getItem('accessToken'),
                      documentUUID: doc.uuid,
                    })
                  }}
                  onResolveComment={async (selectedComment: any) => {
                    if (selectedComment) {
                      tiptapEditorRef?.current?.unsetComment(selectedComment.uuid)
                      const comment = await io.emitWithAck('resolve_comment', {
                        token: localStorage.getItem('accessToken'),
                        documentUUID: doc.uuid,
                        commentUUID: selectedComment.uuid,
                      })
                      const commentUUID = selectedComment.uuid
                      try {
                        setDoc((prev: any) => {
                          switch (variant.toUpperCase()) {
                            case 'PROPOSAL':
                              return {
                                ...prev,
                                proposal: {
                                  ...prev.proposal,
                                  comments: (prev.proposal?.comments ?? []).map((comment: any) => {
                                    if (comment.uuid === commentUUID) {
                                      return {
                                        ...comment,
                                        isResolved: true,
                                      }
                                    } else {
                                      return comment
                                    }
                                  }),
                                },
                              }
                            case 'QUOTATION':
                              console.log(prev)
                              return {
                                ...prev,
                                quotation: {
                                  ...prev.quotation,
                                  comments: (prev.quotation?.comments ?? []).map((comment: any) => {
                                    console.log(comment, commentUUID)
                                    if (comment.uuid === commentUUID) {
                                      return {
                                        ...comment,
                                        isResolved: true,
                                      }
                                    } else {
                                      return comment
                                    }
                                  }),
                                },
                              }
                            case 'OFFER':
                              return {
                                ...prev,
                                offer: {
                                  ...prev.offer,
                                  comments: (prev.offer?.comments ?? []).map((comment: any) => {
                                    if (comment.uuid === commentUUID) {
                                      return {
                                        ...comment,
                                        isResolved: true,
                                      }
                                    } else {
                                      return comment
                                    }
                                  }),
                                },
                              }
                          }
                          return prev
                        })
                      } catch (e) {}
                    }
                  }}
                />
              </div>
            )}

            {isPreview && (
              <div className={'flex absolute top-0 left-0 w-full h-full z-50 bg-zinc-800'}>
                <div className="flex w-full h-full">
                  <DocumentPreview
                    url={`${baseURL}/powerdocs/preview/${doc.uuid}/${variant}`}
                    close={() => setIsPreview(false)}
                  />
                </div>
              </div>
            )}
          </div>
        ) : (
          <>
            <div className="flex flex-1 flex-col justify-center content-center items-center">
              <div className={'w-full h-full justify-center items-center flex gap-3'}>
                <Spinner size={'lg'} color="secondary" />
                <span className={'text-black text-xl'}>Retrieving project details…</span>
              </div>
            </div>
            <div></div>
          </>
        )}
      </div>
    </>
  )
}

interface ITranscription {
  text: string
  source: string
  isNew: boolean
}

interface IInsight {
  type: string
  content: string
}

interface IImplementation {
  title: string
  description: string
}
