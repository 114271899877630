import { Color } from '@tiptap/extension-color'
import TextStyle from '@tiptap/extension-text-style'
import ListItem from '@tiptap/extension-list-item'
import Underline from '@tiptap/extension-underline'
import StarterKit from '@tiptap/starter-kit'
import Image from '@tiptap/extension-image'
import { Markdown } from 'tiptap-markdown'
import { getHierarchicalIndexes, TableOfContents } from '@tiptap-pro/extension-table-of-contents'
import { Dropcursor } from '@tiptap/extension-dropcursor'
import OrderedList from '@tiptap/extension-ordered-list'
import GapCursor from '@tiptap/extension-gapcursor'
import { SuperchargedTableExtensions } from './supercharged-table'
import { ResizableMedia } from 'src/pages/powerdoc-view/extensions/resizableMedia'
import { CoverPageNode } from 'src/pages/powerdoc-view/extensions/CoverPage'
import { Paragraph } from '@tiptap/extension-paragraph'
import { Placeholder } from '@tiptap/extension-placeholder'
import { TextAlign } from '@tiptap/extension-text-align'

const ExtendedMarkdown = Markdown.configure({
  html: true,
}).extend({
  addOptions() {
    return {
      ...this.parent?.(),
      renderInline: false,
      html: true,
    }
  },

  addNodeSpecs() {
    return {
      ...this.parent?.(),
      resizableMedia: {
        toMarkdown: (state: any, node: any) => {
          const { src, alt, title, 'media-type': mediaType } = node.attrs
          if (mediaType === 'img') {
            state.write(`![${alt || ''}](${src || ''}${title ? ` "${title}"` : ''})`)
          } else if (mediaType === 'video') {
            state.write(`![video](${src || ''})`)
          }
        },
        parseMarkdown: {
          match: (token) => token?.type === 'inline' && (token.tag === 'img' || token.tag === 'video'),
          runner: (state, token) => {
            const mediaType = token.tag === 'img' ? 'img' : 'video'
            state.addNode('resizableMedia', {
              src: token.attrGet('src'),
              'media-type': mediaType,
              alt: token.attrGet('alt') || null,
              title: token.attrGet('title') || null,
            })
          },
        },
      },
    }
  },
})
import { CommentExtension } from '@sereneinserenade/tiptap-comment-extension'
import { CustomHeading, PermissionFilter } from 'src/pages/powerdoc-view/extensions/SectionDivNode'

export function createExtensions({
  currentUserWithPermission,
  setTableContent,
  suggestions,
  onCommentClicked,
  setShowComment,
}: any) {
  return [
    // PageNode,
    // PaginationPlugin,
    // PaginationExtension,
    PermissionFilter(currentUserWithPermission),
    ExtendedMarkdown.configure({
      html: true,
    }),
    CustomHeading,
    CommentExtension.configure({
      HTMLAttributes: {
        class: 'my-comment',
      },
      onCommentActivated: (commentId) => {
        if (commentId) {
          const element = document.querySelector('[data-comment-id="' + commentId + '"]')
          const rect = element?.getBoundingClientRect()
          onCommentClicked?.(commentId, rect)
        } else {
          setShowComment?.(false)
        }
      },
    }),
    CoverPageNode,
    Color.configure({ types: [TextStyle.name, ListItem.name] }),
    GapCursor,
    Placeholder.configure({
      placeholder: 'Write something…',
    }),
    Paragraph,
    Underline,
    TextStyle,
    OrderedList,
    StarterKit.configure({
      bulletList: {
        keepMarks: true,
        keepAttributes: true,
      },
      orderedList: {
        keepMarks: true,
        keepAttributes: true,
      },
      heading: false,
    }),
    TableOfContents.configure({
      getIndex: getHierarchicalIndexes,
      onUpdate(content) {
        setTableContent(content)
      },
    }),
    Dropcursor.configure({
      color: 'blue',
      width: 3,
      class: 'prosemirror-dropcursor',
    }),
    ResizableMedia,
    Image.configure({
      allowBase64: true,
    }),
    ...SuperchargedTableExtensions,
    TextAlign.configure({
      types: ['heading', 'paragraph'],
    }),
    // Pagination.configure({
    //   pageHeight: 1056, // default height of the page
    //   pageWidth: 816,   // default width of the page
    //   pageMargin: 96,   // default margin of the page
    // }),
  ]
}
