import React, { useEffect, useMemo } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements, useParams } from 'react-router-dom'

// import Feedback from 'simple-screenshot-feedback'
import { SocketProvider } from './context/socket'
import Layout from './pages/layout' // https://reactrouter.com/en/main/route/route#layout-routes
import Home from './pages/home'
import Login from './pages/login'
import Signup from './pages/signup'
import Download from './pages/download'
import Settings from './pages/settings'
import Team from './pages/team'
import SupervisorMeetings from './pages/supervisor'
import VerfiyUser from 'src/pages/VerifyUser'
import Pricing from './pages/pricing'
import { WorkspaceProvider } from 'src/context/workspace'
import ResetPassword from 'src/pages/reset'
import Invitation from 'src/pages/invitation'
import { MilkdownProvider } from '@milkdown/react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { googleSiteKey } from './lib/env'
import UrsPageSupervisor from 'src/pages/supervisor/urds'
import Mixpanel from './lib/mixpanel'
import { SubscriptionProvider } from 'src/context/subscription'
import ClientPage from './pages/client'
import Audit from 'src/pages/audit'
import Submission from 'src/pages/submissions'
import Error404 from 'src/pages/404'
import SettingsLanding from 'src/pages/settings/landing'
import { Crisp } from 'crisp-sdk-web'
import CallbackAuth from 'src/pages/login/CallbackAuth'
import PowerDocsList from 'src/pages/powerdocs'
import PowerDocument from 'src/pages/powerdoc-view'
import PowerDocProjectOverView from '@/pages/powerdoc-project-view'

const SettingsWithTab = () => {
  const { tab } = useParams()
  return <Settings tab={tab} />
}

export default function App() {
  const queryClient = useMemo(() => new QueryClient({}), [])

  useEffect(() => {
    const userString = localStorage.getItem('user')
    if (userString) {
      try {
        const user = JSON.parse(userString)
        Mixpanel.login(user.uuid)
      } catch (err) {}
    }
  }, [])

  useEffect(() => {
    Crisp.configure('db4acea6-4989-453d-b963-92ed4fe0a0ef')
  }, []) // Empty dependency array ensures it runs only once after mounting

  return (
    <GoogleReCaptchaProvider reCaptchaKey={googleSiteKey}>
      <MilkdownProvider>
        <WorkspaceProvider>
          <SocketProvider>
            <QueryClientProvider client={queryClient}>
              <SubscriptionProvider>
                <RouterProvider
                  router={createBrowserRouter(
                    createRoutesFromElements(
                      <Route element={<Layout />}>
                        <Route path="/" element={<Home />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/auth/:social/callback" element={<CallbackAuth />} />
                        <Route path="/signup" element={<Signup />} />
                        <Route path="/invitation/:invitationUUID" element={<Invitation />} />
                        <Route path="/account/reset/:token" element={<ResetPassword />} />
                        <Route path="/verify/user/:token" element={<VerfiyUser />} />
                        <Route path="/verify/user/" element={<VerfiyUser />} />
                        <Route path="/create" element={<Home />} />
                        <Route
                          path="/document/:documentUUID"
                          element={<PowerDocument variant={'proposal'} isReadOnly={true} />}
                        />
                        <Route
                          path="/proposal/:documentUUID"
                          element={<PowerDocument variant={'proposal'} isReadOnly={true} />}
                        />
                        <Route
                          path="/offer/:documentUUID"
                          element={<PowerDocument variant={'offer'} isReadOnly={true} />}
                        />
                        <Route
                          path="/quotation/:ursUUID"
                          element={<PowerDocument variant={'quotation'} isReadOnly={true} />}
                        />
                        <Route path="/supervisor/urs/:ursUUID" element={<UrsPageSupervisor />} />
                        <Route path="/settings" element={<SettingsLanding />} />
                        <Route path="/settings/:tab" element={<SettingsWithTab />} />
                        <Route path="/team" element={<Team />} />
                        <Route path="/audit" element={<Audit />} />
                        <Route path="/submissions" element={<Submission />} />
                        <Route path="/powerdocs" element={<PowerDocsList />} />
                        <Route path="/client/:clientUUID" element={<ClientPage />} />
                        <Route
                          path="/client/:clientUUID/document/:documentUUID"
                          element={<PowerDocProjectOverView />}
                        />
                        <Route
                          path="/client/:clientUUID/document/:documentUUID/overview"
                          element={<PowerDocProjectOverView />}
                        />
                        <Route
                          path="/client/:clientUUID/document/:documentUUID/proposal"
                          element={<PowerDocument variant={'proposal'} />}
                        />
                        <Route
                          path="/client/:clientUUID/document/:documentUUID/quotation"
                          element={<PowerDocument variant={'quotation'} />}
                        />
                        <Route
                          path="/client/:clientUUID/document/:documentUUID/offer"
                          element={<PowerDocument variant={'offer'} />}
                        />
                        <Route path="/client/:clientUUID/project/:projectUUID" element={<PowerDocsList />} />
                        <Route
                          path="/supervisor"
                          element={<SupervisorMeetings />}
                          handle={{
                            crumb: () => ({ href: `/supervisor`, name: 'Supervisor View', key: 'supervisor' }),
                          }}
                        />
                        <Route
                          path="/download"
                          element={<Download />}
                          loader={async () => {
                            console.log('Download starting')
                            return {}
                          }}
                        />
                        <Route path="/pricing" element={<Pricing />} />
                        <Route path="*" element={<Error404 />} />
                      </Route>,
                    ),
                  )}
                />
                {/* <Feedback
                  slackToken="xoxb-397530728805-6237601335397-xBkXPySkrvwRzDcFEQzGCMQO"
                  slackChannel="#feedback"
                  handleSubmitError={(errormsg: any) => console.log(errormsg)}
                  location="top-right"
                /> */}
                <ReactQueryDevtools />
              </SubscriptionProvider>
            </QueryClientProvider>
          </SocketProvider>
        </WorkspaceProvider>
      </MilkdownProvider>
    </GoogleReCaptchaProvider>
  )
}
