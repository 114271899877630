import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  Progress,
  Textarea,
  useRadio,
  VisuallyHidden,
  cn,
  Spinner,
  ScrollShadow,
  ModalFooter,
  ModalHeader,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Chip,
  Tooltip,
  // Removed Text from import
} from '@nextui-org/react'

import StepWizard from 'react-step-wizard'
import './StepWizard.css'

import { useWorkspaceContextData } from 'src/context/workspace'
import { useSocketContextData } from 'src/context/socket'
import { useNavigate, useParams } from 'react-router-dom'
import CreatableSelect from 'react-select/creatable'
import { useSubscriptionContext } from 'src/context/subscription'
import Mixpanel from 'src/lib/mixpanel'
import { Icon } from '@iconify/react'
import { emitter } from 'src/lib/EventEmitter'
import { useDropzone } from 'react-dropzone'
import api from 'src/lib/api'
import { PowerDocsEventsName } from 'src/types/SocketEvents'

const inActiveButtonClass = 'bg-transparent text-default-400 pl-5 pr-5'
const activeButtonClass = 'text-black-500 font-medium bg-white rounded-md  pl-5 pr-5'

// ------------------ Stepper Indicator ------------------ //
function StepperIndicator({ currentStep, totalSteps }: { currentStep: number; totalSteps: number }) {
  const steps = Array.from({ length: totalSteps }, (_, index) => index + 1)
  const stepsTitles: { [key: number]: string } = { 1: 'Type', 2: 'Brief', 3: 'Details' }

  return (
    <div className="flex items-center justify-center gap-2">
      {steps.map((step, index) => (
        <React.Fragment key={step}>
          <div className="flex flex-col items-center">
            <div
              className={`w-8 h-8 flex items-center justify-center rounded-full border-2 ${
                currentStep === step
                  ? 'bg-secondary border-secondary text-white'
                  : 'bg-white border-default-300 text-default-500'
              }`}
            >
              {step}
            </div>
            <span className="text-xs mt-1">{stepsTitles[step]}</span>
          </div>
          {index < steps.length - 1 && <Icon icon="lucide:chevron-right" width="20" className="mx-2" />}
        </React.Fragment>
      ))}
    </div>
  )
}

// ------------------ CustomRadio ------------------ //
export const CustomRadio = (props) => {
  const {
    Component,
    children,
    isSelected,
    description,
    getBaseProps,
    getWrapperProps,
    getInputProps,
    getLabelProps,
    getLabelWrapperProps,
    getControlProps,
  } = useRadio(props)

  return (
    <Component
      {...getBaseProps()}
      className={cn(
        'group inline-flex items-center hover:opacity-70 active:opacity-50 justify-between flex-row-reverse tap-highlight-transparent',
        'max-w-[300px] cursor-pointer border-2 border-default rounded-lg gap-4 p-2',
        'data-[selected=true]:border-secondary',
      )}
    >
      <VisuallyHidden>
        <input {...getInputProps()} />
      </VisuallyHidden>
      <span {...getWrapperProps()} style={isSelected ? { borderColor: '#65d0ca' } : {}}>
        <span {...getControlProps()} style={isSelected ? { backgroundColor: '#65d0ca' } : {}} />
      </span>
      <div {...getLabelWrapperProps()}>
        {children && <span {...getLabelProps()}>{children}</span>}
        {description && <span className="text-small text-foreground opacity-70">{description}</span>}
      </div>
    </Component>
  )
}

// ------------------ STEP 1: Document Type ------------------ //
function StepDocumentType({ formData, setFormData }) {
  const handleSelectDocumentType = (type: string) => {
    setFormData((prev: any) => ({ ...prev, documentType: type }))
  }

  return (
    <div className="flex flex-col gap-8 p-2">
      <h2 className="text-xl font-semibold">DOCUMENT TYPE</h2>
      <div className="grid grid-cols-2 gap-4">
        <div
          className={`flex flex-col gap-3 border-2 p-4 rounded-lg cursor-pointer ${
            formData.documentType === 'PROPOSAL' ? 'border-secondary' : 'border-default-200'
          }`}
          onClick={() => handleSelectDocumentType('PROPOSAL')}
        >
          <div className="border rounded-xl bg-default-100 border-none p-3 w-fit">
            <Icon icon="lucide:file-text" width="20" />
          </div>
          <div>
            <h3 className="text-lg font-medium">Proposal</h3>
            <p className="text-sm text-default-500">
              High-level pitch, outlining approach, value, and potential solutions.
            </p>
          </div>
          <div className="flex items-center gap-1 text-secondary uppercase text-tiny font-semibold">
            Get started <Icon icon="lucide:arrow-right" width="20" />
          </div>
        </div>
        <div
          className={`flex flex-col gap-3 border-2 p-4 rounded-lg cursor-pointer ${
            formData.documentType === 'QUOTATION' ? 'border-secondary' : 'border-default-200'
          }`}
          onClick={() => handleSelectDocumentType('QUOTATION')}
        >
          <div className="border rounded-xl bg-default-100 border-none p-3 w-fit">
            <Icon icon="lucide:file-check-2" width="20" />
          </div>
          <div>
            <h3 className="text-lg font-medium">Quotation</h3>
            <p className="text-sm text-default-500">
              Breakdown of costs, deliverables, and timeline, giving a financial scope.
            </p>
          </div>
          <div className="flex items-center gap-1 text-secondary uppercase text-tiny font-semibold">
            Get started <Icon icon="lucide:arrow-right" width="20" />
          </div>
        </div>
        <div
          className={`flex flex-col gap-3 border-2 p-4 rounded-lg cursor-pointer ${
            formData.documentType === 'OFFER' ? 'border-secondary' : 'border-default-200'
          }`}
          onClick={() => handleSelectDocumentType('OFFER')}
        >
          <div className="border rounded-xl bg-default-100 border-none p-3 w-fit">
            <Icon icon="lucide:file-spreadsheet" width="20" />
          </div>
          <div>
            <h3 className="text-lg font-medium">Offer</h3>
            <p className="text-sm text-default-500">
              The formal agreement to proceed with the work based on prior discussions.
            </p>
          </div>
          <div className="flex items-center gap-1 text-secondary uppercase text-tiny font-semibold">
            Get started <Icon icon="lucide:arrow-right" width="20" />
          </div>
        </div>
        <div
          className={`flex flex-col gap-3 border-2 p-4 rounded-lg cursor-not-allowed opacity-50 ${
            formData.documentType === 'CONTRACT' ? 'border-secondary' : 'border-default-200'
          }`}
        >
          <div className="flex justify-between">
            <div className="border rounded-xl bg-default-100 border-none p-3 w-fit">
              <Icon icon="lucide:file-lock-2" width="20" />
            </div>
            <Chip color="secondary">Coming soon</Chip>
          </div>
          <div>
            <h3 className="text-lg font-medium">Contract</h3>
            <p className="text-sm text-default-500">
              Legally binding document detailing obligations, responsibilities, and terms for all parties.
            </p>
          </div>
          <div className="flex items-center gap-1 text-secondary uppercase text-tiny font-semibold">
            Get started <Icon icon="lucide:arrow-right" width="20" />
          </div>
        </div>
      </div>
    </div>
  )
}

// ------------------ STEP 2: Project Info ------------------ //
function StepProjectInfo({
  formData,
  setFormData,
  clientsFullList,
  selectedClient,
  setSelectedClient,
  loadScope,
  errors,
  setNextButtonStatus,
}) {
  const [progress, setProgress] = useState(0)
  const [variant, setVariant] = useState<'danger' | 'warning' | 'secondary'>('danger')
  const [uploading, setUpload] = useState<boolean>(false)
  const [training, setTraining] = useState<boolean>(false)
  const [reseting, setReseting] = useState<boolean>(false)
  const [filesDescription, setFilesDescription] = useState<string>('')
  const uploadDiv = useRef<HTMLDivElement>(null)
  const trainingDiv = useRef<HTMLDivElement>(null)
  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    setFormData((prev: any) => ({
      ...prev,
      filesToUpload: acceptedFiles,
    }))
    trainingDiv.current?.classList.add('hidden')
    uploadDiv.current?.classList.remove('hidden')
    setUpload(true)
    const formData = new FormData()
    acceptedFiles.map((file: File) => formData.append('files', file))
    const res = await api.post(`/urs/files`, formData)
    setUpload(false)
    setFormData((prev: any) => ({
      ...prev,
      filesToUpload: [],
    }))
    if (res.data?.data?.length > 0) {
      setFormData((prev: any) => ({
        ...prev,
        files: [...prev.files, ...res.data.data],
      }))
    }
  }, [])
  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    },
    maxFiles: 5 - formData.files?.length,
  })
  const { io } = useSocketContextData()
  const resetSettings = () => {
    setFormData((prev: any) => ({
      ...prev,
      filesDescription: '',
      files: [],
      filesToUpload: [],
    }))
  }
  const trainData = async () => {
    uploadDiv.current?.classList.add('hidden')
    trainingDiv.current?.classList.remove('hidden')
    setNextButtonStatus(false)
    setTraining(true)
    const content = await io.emitWithAck('extractDescription', {
      files: formData.files,
      token: localStorage.getItem('accessToken'),
    })
    setFilesDescription(content)
    setFormData((prev: any) => ({
      ...prev,
      filesDescription: content,
      files: formData.files,
    }))
    setTraining(false)
    setNextButtonStatus(true)
    loadScope?.(formData.description + '\n' + content)
  }
  useEffect(() => {
    const words = formData?.description?.split(' ').length - 1 || 0
    const newProgress = Math.min((words / 20) * 100, 100)
    setProgress(newProgress)
    if (newProgress < 50) setVariant('danger')
    else if (newProgress < 90) setVariant('warning')
    else setVariant('secondary')
  }, [formData?.description])

  return (
    <div className="flex flex-col gap-8 p-2">
      <h2 className="text-xl font-semibold">PROJECT BRIEF</h2>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col md:flex-row gap-6">
          <div className="flex-1">
            <label className="text-sm pb-1 block">Project title</label>
            <Input
              isRequired
              variant="bordered"
              placeholder="e.g. Redesign of Running App 'Explorun'"
              classNames={{ label: 'text-secondary', inputWrapper: 'h-[55px]' }}
              value={formData?.title ?? ''}
              onChange={(e) => setFormData((prev: any) => ({ ...prev, title: e.target.value }))}
              isInvalid={errors.title}
            />
            {errors.title && <span className="text-red-500 text-xs">Project title is required</span>}
          </div>
          <div className="flex-1">
            <label className="text-sm pb-1 block">Client name</label>
            <CreatableSelect
              options={clientsFullList.map((item: any) => ({
                value: item.uuid,
                label: item.name,
              }))}
              placeholder="Select or type new"
              value={selectedClient}
              onChange={(value) => {
                setFormData((prev: any) => ({
                  ...prev,
                  clientName: value?.label,
                }))
                setSelectedClient(value)
              }}
              onCreateOption={(inputValue: string) => {
                setFormData((prev: any) => ({
                  ...prev,
                  clientName: inputValue,
                }))
                setSelectedClient({ label: inputValue, value: null })
              }}
              formatCreateLabel={(inputValue: string) => `Create new client: ${inputValue}`}
              menuPosition="fixed"
              menuPortalTarget={document.body}
              styles={{
                container: (baseStyles) => ({
                  ...baseStyles,
                  minWidth: '100%',
                  borderColor: errors.clientName ? '#f31260' : '#e5e7eb',
                  borderWidth: 2,
                  borderRadius: 8,
                  padding: 4,
                  height: '55px',
                  boxShadow: 'none',
                }),
                control: (baseStyles) => ({
                  ...baseStyles,
                  border: 'none',
                  height: '100%',
                  width: '100%',
                  outline: 'none',
                  boxShadow: 'none',
                }),
                menuPortal: (defaultStyles) => ({
                  ...defaultStyles,
                  zIndex: 99999,
                }),
              }}
            />
            {errors.clientName && <span className="text-red-500 text-xs">Client name is required</span>}
          </div>
        </div>
        <div className="flex flex-col">
          <Textarea
            minRows={4}
            maxRows={6}
            variant="bordered"
            labelPlacement="outside"
            label="Project description & special requirements"
            placeholder="Provide a concise client brief for this document, including key objectives, requirements, and any specific details to consider."
            value={formData?.description ?? ''}
            onChange={(e) => setFormData((prev) => ({ ...prev, description: e.target.value }))}
            onBlur={(e) => loadScope(e.target.value + '\n' + filesDescription)}
            isInvalid={errors.description}
          />
          {errors.description && <span className="text-red-500 text-xs">Project description is required</span>}
          <Progress color={variant} aria-label="Prompt progress" value={progress} className="w-full mt-2" />
          {progress > 0 && (
            <span className={`text-sm text-${variant}`}>
              {progress < 50 ? 'Weak brief!' : progress < 90 ? 'Keep going!' : 'Good brief!'}
            </span>
          )}
        </div>
        <div>
          <span className={'flex flex-row text-sm items-center gap-2'}>
            Client Briefing{' '}
            <Tooltip
              className="w-[200px] px-1.5 text-tiny text-default-600 rounded-small"
              content="Enter your custom text here to fine-tune the AI agent’s responses. Provide examples or specific instructions for how you’d like the agent to behave or respond."
            >
              <Icon icon={'lucide:circle-help'} />
            </Tooltip>
          </span>
          <span className={'text-xs text-default-400'}>
            {'Upload up to 5 past documents, and our AI will analyze them in order to better generate your document.'}
          </span>
          <div
            {...getRootProps({
              className: 'flex flex-col gap-2 mt-2 bg-default-100 rounded-xl justify-center items-center',
            })}
          >
            <input {...getInputProps()} />
            {[...formData.files, ...formData.filesToUpload].length > 0 ? (
              <div className={'flex flex-col justify-center items-center gap-4 p-4 w-full'}>
                <div className={'grid grid-cols-2 gap-4 w-full'}>
                  {[...formData.files, ...formData.filesToUpload].map((file: File | { fileName: string }) => {
                    return (
                      <div className={'bg-white flex rounded flex-row p-2'}>
                        <img src={'/assets/icons/file-pdf.png'} width={40} height={50} />
                        <div className={''}>
                          <p className={'text-xs'}>{(file as File).name ?? (file as any).fileName}</p>
                          <p className={'text-xs'}>PDF File</p>
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className={'flex w-full justify-center items-center gap-1 hidden'} ref={uploadDiv}>
                  <div className={'flex flex-1 h-[1px] bg-black opacity-20'} />
                  <div
                    className={
                      'pt-1 pb-1 pr-6 pl-6 rounded-2xl top-[-4px] bg-warning-400 items-center justify-center flex gap-2 border-2 border-white'
                    }
                  >
                    {uploading && <Spinner size={'sm'} color={'white'} />}
                    <span className={'text-xs text-white'}>{uploading ? 'Uploading files' : 'Document Uploaded'}</span>
                  </div>
                  <div className={'flex flex-1 h-[1px] bg-black opacity-20'} />
                </div>
                <div className={'flex w-full justify-center items-center gap-1 hidden'} ref={uploadDiv}>
                  <div className={'flex flex-1 h-[1px] bg-black opacity-20'} />
                  <div
                    className={
                      'pt-1 pb-1 pr-6 pl-6 rounded-2xl top-[-4px] bg-warning-400 items-center justify-center flex gap-2 border-2 border-white'
                    }
                  >
                    {uploadDiv && <Spinner size={'sm'} color={'white'} />}
                    <span className={'text-xs text-white'}>{uploading ? 'Uploading' : 'Document Uploaded'}</span>
                  </div>
                  <div className={'flex flex-1 h-[1px] bg-black opacity-20'} />
                </div>
                <div className={'flex w-full justify-center items-center gap-1 hidden'} ref={trainingDiv}>
                  <div className={'flex flex-1 h-[1px] bg-black opacity-20'} />
                  <div
                    className={
                      'pt-1 pb-1 pr-6 pl-6 rounded-2xl top-[-4px] bg-warning-400 items-center justify-center flex gap-2 border-2 border-white'
                    }
                  >
                    {training && <Spinner size={'sm'} color={'white'} />}
                    <span className={'text-xs text-white'}>
                      {training ? 'Processing your files' : 'File processed'}
                    </span>
                  </div>
                  <div className={'flex flex-1 h-[1px] bg-black opacity-20'} />
                </div>
                <div className={'flex flex-row flex-1 justify-between items-center p-4 w-full pt-0'}>
                  <Button
                    variant={'bordered'}
                    color={'default'}
                    style={{ borderColor: '#1c1c1c' }}
                    isDisabled={uploading || formData.files?.length === 5 || reseting}
                    onClick={() => {
                      open()
                    }}
                  >
                    <Icon icon={'lucide:plus'} color={'#1c1c1c'} />
                    <span>Upload file</span>
                  </Button>
                  <div className={'flex flex-row gap-4'}>
                    <Button
                      variant={'bordered'}
                      color={'default'}
                      style={{ borderColor: '#1c1c1c' }}
                      isDisabled={uploading || reseting}
                      onClick={resetSettings}
                    >
                      {reseting && <Spinner size={'sm'} />}
                      <span>{reseting ? 'Resetting' : 'Remove all Files'}</span>
                    </Button>
                    <Button
                      variant={'solid'}
                      color={'primary'}
                      style={{ borderColor: '#1c1c1c' }}
                      isDisabled={uploading || reseting || training}
                      onClick={trainData}
                    >
                      <span>Scan & Process now</span>
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div className={'flex flex-col justify-center items-center p-4'}>
                <div className={'bg-white p-2 rounded-full mb-2'}>
                  <Icon icon={'lucide:cloud-upload'} />
                </div>
                <p className={'font-regular text-sm'}>
                  <span className={'pr-1 font-medium text-blue-400 cursor-pointer'} onClick={open}>
                    Click to upload or drag and drop
                  </span>
                  <span className={'font-light text-blue-400 '}>(max 5 files)</span>
                </p>
                <span className={'text-xs font-regular'}>PDF or DOCX (max. 5mo)</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

// ------------------ STEP 3: Project Scope & Generate ------------------ //
function StepProjectScope({
  formData,
  setFormData,
  scopes,
  scopesLoading,
  showAdvancedOption,
  setShowAdvancedOption,
  defaultDocLength,
  setDefaultDocLength,
  formality,
  setFormality,
  modeOfAddress,
  setModeOfAddress,
  audianceType,
  setAudianceType,
  language,
  setLanguage,
  fineTuneText,
  setFineTuneText,
  loading,
}) {
  return (
    <div className="flex flex-col gap-6 p-2">
      <h2 className="text-xl font-semibold">PROJECT DETAILS</h2>
      {(scopesLoading || scopes.length > 0) && (
        <div className="flex flex-col">
          <p className="pb-4 text-small leading-5 text-default-500">
            Select any relevant Scope of Work elements that apply to the project. These suggestions are optional but can
            help refine the business document by providing our AI with more context.
          </p>
          {scopesLoading && (
            <div className="w-full flex justify-center gap-2">
              <Spinner size="sm" />
              <span className="font-light opacity-40 text-default-500 text-sm">Loading scope of work options...</span>
            </div>
          )}
          {!scopesLoading && scopes.length > 0 && (
            <div className="flex flex-wrap gap-2">
              {scopes.map((scope: string) => (
                <Button
                  key={scope}
                  onPress={() => {
                    const prev = [...(formData?.scopeOfProject ?? [])]
                    const index = prev.indexOf(scope)
                    if (index > -1) prev.splice(index, 1)
                    else prev.push(scope)
                    setFormData((frm: any) => ({ ...frm, scopeOfProject: prev }))
                  }}
                  className={
                    formData?.scopeOfProject?.includes(scope)
                      ? 'px-3 py-2 text-white font-medium'
                      : 'px-3 py-2 text-black font-medium'
                  }
                  variant={formData?.scopeOfProject?.includes(scope) ? 'solid' : 'bordered'}
                  color="secondary"
                  radius="sm"
                >
                  {scope}
                </Button>
              ))}
            </div>
          )}

          <div
            className="flex items-center mt-10 gap-1 cursor-pointer"
            onClick={() => setShowAdvancedOption((prev) => !prev)}
          >
            <Icon icon="lucide:settings" />
            {showAdvancedOption ? 'Hide advanced settings' : 'Show advanced settings'}
          </div>
        </div>
      )}
      {showAdvancedOption && (
        <div className="flex flex-col gap-6 pt-4">
          <h2 className="text-lg font-semibold">Advanced Settings</h2>
          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-1">
              <span className="flex items-center gap-2">
                Document length <Icon icon="lucide:circle-help" />
              </span>
              <div className="p-1 rounded-xl w-fit bg-default-100">
                {['Concise', 'Precise', 'Detailed'].map((item) => (
                  <Button
                    key={item}
                    size="sm"
                    className={defaultDocLength === item ? activeButtonClass : inActiveButtonClass}
                    onPress={() => setDefaultDocLength(item)}
                  >
                    {item}
                  </Button>
                ))}
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <span className="flex items-center gap-2">
                Document language <Icon icon="lucide:circle-help" />
              </span>
              <Dropdown>
                <DropdownTrigger>
                  <Button variant="faded" className="bg-white">
                    {language}
                  </Button>
                </DropdownTrigger>
                <DropdownMenu aria-label="languages-list">
                  {['English', 'English (UK)', 'Spanish', 'French', 'German', 'Arabic', 'Japanese'].map((lang) => (
                    <DropdownItem
                      key={lang}
                      className={lang === language ? 'bg-default-300' : ''}
                      onPress={() => setLanguage(lang)}
                    >
                      {lang}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div>
            <div className="flex flex-col gap-1">
              <span className="flex items-center gap-2">
                Formality <Icon icon="lucide:circle-help" />
              </span>
              <div className="p-1 rounded-xl w-fit bg-default-100">
                {['Informal', 'Semi-formal', 'Professional'].map((item) => (
                  <Button
                    key={item}
                    size="sm"
                    className={formality === item ? activeButtonClass : inActiveButtonClass}
                    onPress={() => setFormality(item)}
                  >
                    {item}
                  </Button>
                ))}
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <span className="flex items-center gap-2">
                Mode of address <Icon icon="lucide:circle-help" />
              </span>
              <div className="p-1 rounded-xl w-fit bg-default-100">
                {['First-person tone', 'Second-person tone'].map((item) => (
                  <Button
                    key={item}
                    size="sm"
                    className={modeOfAddress === item ? activeButtonClass : inActiveButtonClass}
                    onPress={() => setModeOfAddress(item)}
                  >
                    {item}
                  </Button>
                ))}
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <span className="flex items-center gap-2">
                Audience type <Icon icon="lucide:circle-help" />
              </span>
              <div className="p-1 rounded-xl w-fit bg-default-100">
                {['Experts', 'General readers', 'Technical users'].map((item) => (
                  <Button
                    key={item}
                    size="sm"
                    className={audianceType === item ? activeButtonClass : inActiveButtonClass}
                    onPress={() => setAudianceType(item)}
                  >
                    {item}
                  </Button>
                ))}
              </div>
            </div>
            <Textarea
              label={
                <span className="flex flex-row items-center gap-2">
                  Text input for fine-tuning <Icon icon="lucide:circle-help" />
                </span>
              }
              placeholder="Enter any additional requirements or stylistic preferences..."
              labelPlacement="outside"
              value={fineTuneText}
              onChange={(e) => setFineTuneText(e.target.value)}
            />
          </div>
        </div>
      )}
    </div>
  )
}

// ------------------ MAIN COMPONENT: CreateNewDocument ------------------ //
export default function CreateNewDocument(props: any) {
  const { isOpen, onClose, projectUUID, activeClient, showSubscriptionError } = props
  const [activeStep, setActiveStep] = useState(1)
  const [formData, setFormData] = useState<any>({
    projectUUID,
    documentType: 'PROPOSAL',
    paymentTerms: 'FIXED',
    documentLength: 'MEDIUM',
    scopeOfProject: [],
    files: [],
    filesToUpload: [],
  })
  const wizard = useRef<StepWizard>(null)

  // New error state for validation in Step 2
  const [errors, setErrors] = useState({ title: false, clientName: false, description: false })

  const handleStepChange = (e) => {
    if (e.activeStep !== activeStep) {
      // Prevent duplicate tracking if the step is unchanged
      setActiveStep(e.activeStep)

      Mixpanel.track('Document wizard step viewed', {
        step_number: e.activeStep,
        step_name:
          e.activeStep === 1
            ? 'Document Type'
            : e.activeStep === 2
            ? 'Project Brief'
            : e.activeStep === 3
            ? 'Project Details'
            : 'Unknown Step',
      })
    }
  }
  const [scopes, setScopes] = useState<string[]>([])
  const [scopesLoading, setScopesLoading] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [showAdvancedOption, setShowAdvancedOption] = useState<boolean>(false)

  const [fineTuneText, setFineTuneText] = useState<string>('')
  const [defaultDocLength, setDefaultDocLength] = useState<string>('Precise')
  const [formality, setFormality] = useState<string>('Semi-formal')
  const [modeOfAddress, setModeOfAddress] = useState<string>('Second-person tone')
  const [audianceType, setAudianceType] = useState<string>('General readers')
  const [language, setLanguage] = useState<string>('English')

  const { clientUUID } = useParams()
  const { io } = useSocketContextData()
  const { getUserSubscription } = useSubscriptionContext()
  const { loadMyClient, activeWorkspace, clientsFullList, workspace } = useWorkspaceContextData()
  const navigate = useNavigate()

  const [selectedClient, setSelectedClient] = useState<{ value: string; label: string } | null>(null)
  useEffect(() => {
    if (activeClient) {
      setSelectedClient({ value: activeClient.uuid, label: activeClient.name })
      setFormData((prev) => ({ ...prev, clientName: activeClient.name }))
    }
  }, [activeClient])
  useEffect(() => {
    // For external close calls
    // @ts-ignore
    window.closeCreateModal = onClose
  }, [onClose])
  useEffect(() => {
    if (workspace) {
      setFineTuneText(workspace.fineTuneText ?? '')
      setAudianceType(workspace.audianceType ?? audianceType)
      setDefaultDocLength(workspace.defaultDocLength ?? defaultDocLength)
      setFormality(workspace.formality ?? formality)
      setModeOfAddress(workspace.modeOfAddress ?? modeOfAddress)
      setLanguage(workspace.language ?? language)
    }
  }, [workspace])

  const loadScope = async (description: string) => {
    if (description && io) {
      setScopesLoading(true)
      try {
        const response = await io.emitWithAck('getScopes', { description })
        if (response) {
          setScopes(response.split('\n'))
        }
      } catch (err) {
        console.error(err)
      }
      setScopesLoading(false)
    }
  }

  const handleGenerate = async () => {
    setLoading(true)
    try {
      const res = await io.emitWithAck(PowerDocsEventsName.CREATE_DOCUMENT, {
        ...formData,
        workspaceUUID: activeWorkspace,
        projectUUID,
        scope: (formData.scopeOfProject || []).join('\n'),
        token: localStorage.getItem('accessToken'),
        formality,
        modeOfAddress,
        defaultDocLength,
        fineTuneText,
        audianceType,
        language,
        filesToUpload: [],
      })
      if (res.error) {
        console.log('Error raised')
      }
    } catch (error) {
      Mixpanel.track('Document creation error', {
        error_message: error,
      })
      setLoading(false)
    }
  }
  const reset = () => {
    setFormData({
      projectUUID,
      documentType: 'PROPOSAL',
      paymentTerms: 'FIXED',
      documentLength: 'MEDIUM',
      scopeOfProject: [],
      files: [],
      filesToUpload: [],
    })
    setSelectedClient(null)
    wizard.current?.firstStep()
  }
  useEffect(() => {
    if (io) {
      const handleRedirect = (documentType: string, documentUUID: string) => {
        let link = `/client/${clientUUID}/document/${documentUUID}/proposal`
        switch (documentType) {
          case 'QUOTATION':
            link = `/client/${clientUUID}/document/${documentUUID}/quotation`
            break
          case 'OFFER':
            link = `/client/${clientUUID}/document/${documentUUID}/offer`
            break
          default:
            link = `/client/${clientUUID}/document/${documentUUID}/proposal`
        }
        setTimeout(() => {
          setLoading(false)
          navigate(link)
        }, 500)
      }
      io.on(PowerDocsEventsName.PROPOSAL_GENERATED, (res) => {
        loadMyClient?.(activeWorkspace)
        if (res.error) {
          showSubscriptionError?.()
        } else {
          emitter.emit('updateSubmissionCount', { step: 1, uuid: activeWorkspace })
          getUserSubscription?.()
          reset()
          onClose()
          handleRedirect('PROPOSAL', res.uuid)

          Mixpanel.track('Document wizard step completed', {
            step_number: 3,
            step_name: 'Budget & terms',
            document_type: formData.documentType,
          })

          Mixpanel.track('Document created', {
            document_id: res.uuid,
            document_type: formData.documentType,
          })
        }
      })
      io.on(PowerDocsEventsName.QUOTATION_GENERATED, (res) => {
        loadMyClient?.(activeWorkspace)
        if (res.error) {
          showSubscriptionError?.()
        } else {
          emitter.emit('updateSubmissionCount', { step: 1, uuid: activeWorkspace })
          getUserSubscription?.()
          reset()
          onClose()
          handleRedirect('QUOTATION', res.uuid)
          Mixpanel.track('Document wizard step completed', {
            step_number: 3,
            step_name: 'Budget & terms',
            document_type: formData.documentType,
          })

          Mixpanel.track('Document created', {
            document_id: res.uuid,
            document_type: formData.documentType,
          })
        }
      })
      io.on(PowerDocsEventsName.OFFER_GENERATED, (res) => {
        loadMyClient?.(activeWorkspace)
        if (res.error) {
          showSubscriptionError?.()
        } else {
          emitter.emit('updateSubmissionCount', { step: 1, uuid: activeWorkspace })
          getUserSubscription?.()
          reset()
          onClose()
          handleRedirect('OFFER', res.uuid)
          Mixpanel.track('Document wizard step completed', {
            step_number: 3,
            step_name: 'Budget & terms',
            document_type: formData.documentType,
          })

          Mixpanel.track('Document created', {
            document_id: res.uuid,
            document_type: formData.documentType,
          })
        }
      })
    }
  }, [io])
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState<boolean>(true)
  return (
    <Modal
      className="no-scrollbar"
      backdrop="blur"
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      size="4xl"
      isDismissable={false}
    >
      <div
        onClick={(e) => {
          if (e.target.getAttribute('data-slot') === 'wrapper') {
            reset()
            onClose()
          }
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col items-center gap-4">
                <StepperIndicator currentStep={activeStep} totalSteps={3} />
              </ModalHeader>
              <ModalBody className="scrollbar-hide px-2 md:px-20 pb-10">
                <ScrollShadow hideScrollBar size={100} id="document-wrap-container">
                  <StepWizard ref={wizard} isLazyMount onStepChange={handleStepChange}>
                    <StepDocumentType formData={formData} setFormData={setFormData} />
                    <StepProjectInfo
                      formData={formData}
                      setFormData={setFormData}
                      clientsFullList={clientsFullList}
                      selectedClient={selectedClient}
                      setSelectedClient={setSelectedClient}
                      loadScope={loadScope}
                      errors={errors}
                      setNextButtonStatus={setIsNextButtonEnabled}
                    />
                    <StepProjectScope
                      formData={formData}
                      setFormData={setFormData}
                      scopes={scopes}
                      scopesLoading={scopesLoading}
                      showAdvancedOption={showAdvancedOption}
                      setShowAdvancedOption={setShowAdvancedOption}
                      defaultDocLength={defaultDocLength}
                      setDefaultDocLength={setDefaultDocLength}
                      formality={formality}
                      setFormality={setFormality}
                      modeOfAddress={modeOfAddress}
                      setModeOfAddress={setModeOfAddress}
                      audianceType={audianceType}
                      setAudianceType={setAudianceType}
                      language={language}
                      setLanguage={setLanguage}
                      fineTuneText={fineTuneText}
                      setFineTuneText={setFineTuneText}
                      loading={loading}
                    />
                  </StepWizard>
                </ScrollShadow>
              </ModalBody>
              <ModalFooter>
                <Button
                  variant="bordered"
                  onPress={() => {
                    Mixpanel.track('Document wizard step navigated', {
                      action: 'Previous',
                      from_step: activeStep,
                      to_step: activeStep - 1,
                    })
                    wizard.current?.previousStep()
                  }}
                  isDisabled={activeStep === 1 || loading}
                >
                  Previous
                </Button>
                {activeStep < 3 ? (
                  <Button
                    color="secondary"
                    onPress={() => {
                      // Validate required fields on Step 2
                      if (activeStep === 2) {
                        const newErrors = {
                          title: !formData.title,
                          clientName: !formData.clientName,
                          description: !formData.description,
                        }
                        setErrors(newErrors)
                        if (newErrors.title || newErrors.clientName || newErrors.description) {
                          return
                        }
                      }
                      // Clear errors before moving on
                      setErrors({ title: false, clientName: false, description: false })

                      Mixpanel.track('Document wizard step navigated', {
                        action: 'Next',
                        from_step: activeStep,
                        to_step: activeStep + 1,
                      })

                      wizard.current?.nextStep()
                    }}
                    isDisabled={
                      loading ||
                      !isNextButtonEnabled ||
                      (formData.files.length > 0 &&
                        (!formData.filesDescription || formData.filesDescription.length === 0))
                    }
                  >
                    Next
                  </Button>
                ) : (
                  <Button
                    color="secondary"
                    onPress={handleGenerate}
                    isDisabled={loading}
                    style={{ backgroundColor: 'linear-gradient(0deg, #3D969E 12.9%, #65D0CA 85.52%)' }}
                  >
                    {loading ? 'Generating...' : 'Generate'}
                  </Button>
                )}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </div>
      {loading && isOpen && (
        <div className="absolute w-full h-full top-0 left-0" style={{ zIndex: 1000 }}>
          <div className="bg-black/75 backdrop-blur-sm w-full h-full flex flex-col justify-center items-center gap-2">
            <div className="flex flex-row gap-2 justify-center items-center">
              <Spinner size="lg" color="secondary" />
              <span className="text-white text-xl">Processing information…</span>
            </div>
            <span className="text-white text-sm opacity-60 text-center">
              Please wait and keep this browser tab open
            </span>
          </div>
        </div>
      )}
    </Modal>
  )
}
