import React, { useEffect, useState } from 'react'
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  DropdownSection,
  Button,
  Spinner,
  Divider,
} from '@nextui-org/react'
import { Icon } from '@iconify/react'
import { useSocketContextData } from 'src/context/socket'

import Mixpanel from 'src/lib/mixpanel'
import { useWorkspaceContextData } from 'src/context/workspace'
import { useNavigate, useLocation } from 'react-router-dom'
import { PowerDocsEventsName } from 'src/types/SocketEvents'

export default function DocMenuBar({ isReadOnly, editor, documentUUID, doc, saveMessage }: any) {
  const location = useLocation()
  const activePage = location.pathname.split('/').pop()
  const [openAiMenu, setOpenAiMenu] = React.useState<boolean>(false)
  const { io } = useSocketContextData()
  const menuitems = [
    {
      category: 'basics',
      title: 'Basic adjustments',
      items: [
        {
          key: 'improveClarity',
          label: 'Improve clarity',
          icon: 'lucide:arrow-down-to-dot',
        },
        {
          key: 'makeItLonger',
          label: 'Make longer',
          icon: 'carbon:text-long-paragraph',
        },
        {
          key: 'makeItShorter',
          label: 'Make shorter',
          icon: 'carbon:text-short-paragraph',
        },
        {
          key: 'grammarCheck',
          label: 'Check grammar & style',
          icon: 'lucide:spell-check',
        },
        {
          key: 'paraphrase',
          label: 'Paraphrase',
          icon: 'lucide:arrow-left-right',
        },
      ],
    },
    {
      category: 'tone',
      title: 'Change tone',
      items: [
        {
          key: 'professional',
          label: 'Professional but approachable',
          icon: 'lucide:shirt',
        },
        {
          key: 'creativeEngaging',
          label: 'Creative & engaging',
          icon: 'lucide:drama',
        },
        {
          key: 'formalBusiness',
          label: 'Formal but friendly',
          icon: 'lucide:briefcase-business',
        },
        {
          key: 'persuasive',
          label: 'Persuasive & confident',
          icon: 'lucide:message-circle-warning',
        },
      ],
    },
  ]

  const [aiWorking, setAIWorking] = React.useState<boolean>(false)

  const processSelectedText = async (operationType: string) => {
    if (editor && documentUUID) {
      setAIWorking(true)
      const { state, dispatch } = editor.view
      const { selection, doc } = state
      const selectedText = doc.textBetween(selection.from, selection.to, ' ')
      const result = await io.emitWithAck('textProcess', {
        selectedText,
        content: doc.textContent,
        token: localStorage.getItem('accessToken'),
        operation: operationType,
      })

      // Track menu item click
      Mixpanel.track('Smart text menu AI item clicked', {
        operation: operationType,
        selected_text_length: selectedText.length,
        location: 'Text Editor',
      })

      if (result.text) {
        const tr = state.tr.replaceWith(selection.from, selection.to, state.schema.text(result.text))
        dispatch(tr)
      }
      setAIWorking(false)
    }
  }
  const { activeWorkspace } = useWorkspaceContextData()
  const [isGenerating, setIsGenerating] = useState<boolean>(false)
  const generateQuotation = async () => {
    Mixpanel.track('Generate Quotation clicked', {
      document_id: doc.uuid,
      location: 'Doc Switcher',
    })
    setIsGenerating(true)
    io.emitWithAck(PowerDocsEventsName.GENERATE_QUOTATION, {
      token: localStorage.getItem('accessToken'),
      workspaceUUID: activeWorkspace,
      powerDocUUID: documentUUID,
    })
  }
  const generateProposal = async () => {
    Mixpanel.track('Generate Proposal clicked', {
      document_id: doc.uuid,
      location: 'Doc Switcher',
    })
    setIsGenerating(true)
    io.emitWithAck(PowerDocsEventsName.GENERATE_PROPOSAL, {
      token: localStorage.getItem('accessToken'),
      workspaceUUID: activeWorkspace,
      powerDocUUID: documentUUID,
    })
  }
  const generateOffer = async () => {
    Mixpanel.track('Generate Offer clicked', {
      document_id: doc.uuid,
      location: 'Doc Switcher',
    })
    setIsGenerating(true)
    io.emitWithAck(PowerDocsEventsName.GENERATE_OFFER, {
      token: localStorage.getItem('accessToken'),
      workspaceUUID: activeWorkspace,
      powerDocUUID: documentUUID,
    })
  }
  const navigate = useNavigate()
  useEffect(() => {
    io.on(PowerDocsEventsName.QUOTATION_GENERATED, () => {
      navigate(`/client/${doc.client?.name}/document/${doc.uuid}/quotation`)
    })
    io.on(PowerDocsEventsName.QUOTATION_DONE, () => {
      setIsGenerating(false)
    })
    io.on(PowerDocsEventsName.OFFER_GENERATED, () => {
      navigate(`/client/${doc.client?.name}/document/${doc.uuid}/offer`)
    })
    io.on(PowerDocsEventsName.OFFER_DONE, () => {
      setIsGenerating(false)
    })
    io.on(PowerDocsEventsName.PROPOSAL_GENERATED, () => {
      navigate(`/client/${doc.client?.name}/document/${doc.uuid}/proposal`)
    })
    io.on(PowerDocsEventsName.PROPOSAL_DONE, () => {
      setIsGenerating(false)
    })
  }, [io])
  return (
    <div className="flex sticky w-full px-10 top-5 z-10" editor={editor}>
      <div className={'flex w-full bg-[#E8F2F4] rounded-full gap-2 px-2 py-1 text-default-400 justify-between'}>
        {/* Left DocMenuBar area */}
        {isReadOnly ? (
          <div className="flex h-full items-center justify-center w-[130px]" />
        ) : (
          <div className="flex h-full items-center justify-center ">
            <Dropdown
              classNames={{
                content: 'rounded-xl border border-1 border-success-500 min-w-[200px]',
              }}
              isDisabled={isGenerating}
            >
              <DropdownTrigger>
                {isGenerating ? (
                  <div
                    className={
                      'flex flex-row items-center bg-black w-[130px] text-xs rounded-2xl justify-center gap-2 pt-1 pb-1 text-white h-[80%] cursor-pointer'
                    }
                  >
                    <Spinner size={'sm'} color={'white'} />
                    <span className={'text-xs'}>Generating…</span>
                    <Icon icon={'lucide:chevrons-up-down'} />
                  </div>
                ) : (
                  <div
                    className={
                      'flex flex-row items-center bg-black w-[130px] text-xs rounded-2xl justify-center gap-1 pt-1 pb-1 text-white h-[80%] cursor-pointer'
                    }
                  >
                    <Icon icon={'lucide:codesandbox'} />
                    <span className={'text-xs'}>Doc Switcher</span>
                    <Icon icon={'lucide:chevrons-up-down'} />
                  </div>
                )}
              </DropdownTrigger>
              <DropdownMenu aria-label="Doc generator">
                <DropdownItem
                  key="Proposal"
                  onPress={() => {
                    if (!doc.proposal) {
                      generateProposal()
                    } else {
                      Mixpanel.track('Proposal clicked', {
                        document_id: doc.uuid,
                        location: 'Doc Switcher',
                      })
                      navigate(`/client/${doc.Client?.name?.replace(/ /g, '-')}/document/${doc.uuid}/proposal`)
                    }
                  }}
                  className={`${activePage === 'proposal' ? 'bg-gray-300' : ''}`}
                >
                  <span className="flex flex-row w-full font-bold items-center gap-2">
                    <Icon icon={'lucide:file-text'} />
                    <span className={'text-xs'}>Proposal</span>
                    {activePage !== 'proposal' && !doc.proposal && (
                      <div className={'flex flex-1 justify-end'}>
                        <Button
                          size={'sm'}
                          className={'text-xs pt-0 h-6 rounded-2xl'}
                          variant={'solid'}
                          color={'secondary'}
                          onClick={() => generateProposal()}
                        >
                          <span>Generate</span>
                        </Button>
                      </div>
                    )}
                  </span>
                </DropdownItem>
                <DropdownItem
                  key="Quotation"
                  onPress={() => {
                    if (!doc.quotation) {
                      generateQuotation()
                    } else {
                      Mixpanel.track('Quotation clicked', {
                        document_id: doc.uuid,
                        location: 'Doc Switcher',
                      })
                      navigate(`/client/${doc.Client?.name?.replace(/ /g, '-')}/document/${doc.uuid}/quotation`)
                    }
                  }}
                  className={`${activePage === 'quotation' ? 'bg-gray-300' : ''}`}
                >
                  <span className="flex flex-row w-full font-bold items-center gap-2">
                    <Icon icon={'lucide:feather'} />
                    <span className={'text-xs'}>Quotation</span>
                    {activePage !== 'quotation' && !doc.quotation && (
                      <div className={'flex flex-1 justify-end'}>
                        <Button
                          onClick={() => generateQuotation()}
                          size={'sm'}
                          className={'text-xs pt-0 h-6 rounded-2xl'}
                          variant={'solid'}
                          color={'secondary'}
                        >
                          <span>Generate</span>
                        </Button>
                      </div>
                    )}
                  </span>
                </DropdownItem>
                <DropdownItem
                  key="Offer"
                  onPress={() => {
                    if (!doc.offer) {
                      generateOffer()
                    } else {
                      Mixpanel.track('Offer clicked', {
                        document_id: doc.uuid,
                        location: 'Doc Switcher',
                      })
                      navigate(`/client/${doc.Client?.name?.replace(/ /g, '-')}/document/${doc.uuid}/offer`)
                    }
                  }}
                  className={`${activePage === 'offer' ? 'bg-gray-300' : ''}`}
                >
                  <span className="flex flex-row w-full font-bold items-center gap-2">
                    <Icon icon={'lucide:file-check'} />
                    <span className={'text-xs'}>Offer</span>
                    {activePage !== 'offer' && !doc.offer && (
                      <div className={'flex flex-1 justify-end'}>
                        <Button
                          size={'sm'}
                          onClick={generateOffer}
                          className={'text-xs pt-0 h-6 rounded-2xl'}
                          variant={'solid'}
                          color={'secondary'}
                        >
                          <span>Generate</span>
                        </Button>
                      </div>
                    )}
                  </span>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        )}
        {/* Middle DocMenuBar area */}
        <div className="flex justify-center cursor-pointer gap-0">
          <div className={'flex flex-row justify-center items-center px-1'}>
            <Dropdown
              classNames={{
                content: 'rounded-xl border border-1 border-default-300',
              }}
            >
              <DropdownTrigger>
                <Button variant="light" isDisabled={aiWorking}>
                  {aiWorking ? (
                    <>
                      <div className={'flex flex-row gap-2 w-max justify-center items-center'}>
                        <Spinner size={'sm'} />
                        <span className={'text-dark'}>AI processing...</span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className={'flex flex-row gap-2 w-max justify-center items-center'}
                        id="smart-text-menu-ai-item"
                      >
                        <Icon icon="lucide:sparkles" width={20} />
                        <span className={'text-dark'}>Edit with AI</span>
                        <Icon icon="lucide:chevrons-up-down" />
                      </div>
                    </>
                  )}
                </Button>
              </DropdownTrigger>
              <DropdownMenu aria-label="Dynamic actions">
                {menuitems.map(({ category, title, items }) => (
                  <>
                    <DropdownSection title={title}>
                      {items.map((item) => (
                        <DropdownItem key={item.key} onPress={() => processSelectedText(item.key)}>
                          <span className="flex flex-row w-full font-normal items-center gap-2">
                            <Icon icon={item.icon} className="text-secondary" width={20} />
                            {item.label}
                          </span>
                        </DropdownItem>
                      ))}
                    </DropdownSection>
                  </>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>

          <Divider orientation="vertical" />

          <div className={'flex flex-row gap-1 justify-center items-center px-1'}>
            <div
              className={`p-2 rounded text-black hover:bg-white/50 ${
                editor.isActive('bold') ? 'bg-white' : 'bg-transparent'
              }`}
              onClick={() => {
                editor.chain().focus().toggleBold().run()
                Mixpanel.track('Text formatting changed', {
                  action: 'Bold toggled',
                  page_name: window.location.pathname,
                  location: 'Document Editor Toolbar',
                })
              }}
            >
              <Icon icon="lucide:bold" width={20} />
            </div>

            <div
              className={`p-2 rounded text-black hover:bg-white/50 ${
                editor.isActive('italic') ? 'bg-white' : 'bg-transparent'
              }`}
              onClick={() => {
                editor.chain().focus().toggleItalic().run()
                Mixpanel.track('Text formatting changed', {
                  action: 'Italic toggled',
                  page_name: window.location.pathname,
                  location: 'Document Editor Toolbar',
                })
              }}
            >
              <Icon icon="lucide:italic" width={20} />
            </div>

            <div
              className={`p-2 rounded text-black hover:bg-white/50 ${
                editor.isActive('underline') ? 'bg-white' : 'bg-transparent'
              }`}
              onClick={() => {
                editor.chain().focus().toggleUnderline().run()
                Mixpanel.track('Text formatting changed', {
                  action: 'Underline toggled',
                  page_name: window.location.pathname,
                  location: 'Document Editor Toolbar',
                })
              }}
            >
              <Icon icon="lucide:underline" width={20} />
            </div>
          </div>

          <Divider orientation="vertical" />

          <div className={'flex flex-row gap-1 justify-center items-center px-1'}>
            <div
              className={`p-2 rounded text-black hover:bg-white/50 ${
                editor.isActive('paragraph') ? 'bg-white' : 'bg-transparent'
              }`}
              onClick={() => {
                editor.chain().focus().setNode('paragraph').run()
                Mixpanel.track('Text block type changed', {
                  new_type: 'Paragraph',
                  page_name: window.location.pathname,
                  location: 'Document Editor Toolbar',
                })
              }}
            >
              <Icon icon="lucide:type" width={20} />
            </div>

            {[1, 2, 3].map((level) => (
              <div
                key={level}
                className={`p-2 rounded text-black hover:bg-white/50 ${
                  editor.isActive('heading', { level }) ? 'bg-white' : 'bg-transparent'
                }`}
                onClick={() => {
                  editor.chain().focus().setNode('heading', { level }).run()
                  Mixpanel.track('Text block type changed', {
                    new_type: `Heading ${level}`,
                    page_name: window.location.pathname,
                    location: 'Document Editor Toolbar',
                  })
                }}
              >
                <Icon icon={`lucide:heading-${level}`} width={20} />
              </div>
            ))}
          </div>

          <Divider orientation="vertical" />

          <div className={'flex flex-row gap-1 justify-center items-center px-1'}>
            <div
              className={`p-2 rounded text-black hover:bg-white/50 ${
                editor.isActive({ textAlign: 'left' }) ? 'bg-white' : 'bg-transparent'
              }`}
              onClick={() => {
                editor.chain().focus().setTextAlign('left').run()
                Mixpanel.track('Text alignment changed', {
                  alignment: 'Left',
                  page_name: window.location.pathname,
                  location: 'Document Editor Toolbar',
                })
              }}
            >
              <Icon icon="lucide:align-left" width={20} />
            </div>

            <div
              className={`p-2 rounded text-black hover:bg-white/50 ${
                editor.isActive({ textAlign: 'center' }) ? 'bg-white' : 'bg-transparent'
              }`}
              onClick={() => {
                editor.chain().focus().setTextAlign('center').run()
                Mixpanel.track('Text alignment changed', {
                  alignment: 'Center',
                  page_name: window.location.pathname,
                  location: 'Document Editor Toolbar',
                })
              }}
            >
              <Icon icon="lucide:align-center" width={20} />
            </div>

            <div
              className={`p-2 rounded text-black hover:bg-white/50 ${
                editor.isActive({ textAlign: 'right' }) ? 'bg-white' : 'bg-transparent'
              }`}
              onClick={() => {
                editor.chain().focus().setTextAlign('right').run()
                Mixpanel.track('Text alignment changed', {
                  alignment: 'Right',
                  page_name: window.location.pathname,
                  location: 'Document Editor Toolbar',
                })
              }}
            >
              <Icon icon="lucide:align-right" width={20} />
            </div>
          </div>

          <Divider orientation="vertical" />

          <div className={'flex flex-row gap-1 justify-center items-center px-1'}>
            <div
              className={`p-2 rounded text-black hover:bg-white/50 ${
                editor.isActive('bulletList') ? 'bg-white' : 'bg-transparent'
              }`}
              onClick={() => {
                editor.chain().focus().toggleBulletList().run()
                Mixpanel.track('List format changed', {
                  list_type: 'Bullet List',
                  page_name: window.location.pathname,
                  location: 'Document Editor Toolbar',
                })
              }}
            >
              <Icon icon="lucide:list" width={20} />
            </div>

            <div
              className={`p-2 rounded text-black hover:bg-white/50 ${
                editor.isActive('orderedList') ? 'bg-white' : 'bg-transparent'
              }`}
              onClick={() => {
                editor.chain().focus().toggleOrderedList().run()
                Mixpanel.track('List format changed', {
                  list_type: 'Ordered List',
                  page_name: window.location.pathname,
                  location: 'Document Editor Toolbar',
                })
              }}
            >
              <Icon icon="lucide:list-ordered" width={20} />
            </div>
          </div>

          <Divider orientation="vertical" />

          <div className={'flex flex-row gap-1 justify-center items-center px-1'}>
            <div
              className={`p-2 rounded text-black hover:bg-white/50 ${
                editor.isActive('table') ? 'bg-white' : 'bg-transparent'
              }`}
              onClick={() => {
                editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()
                Mixpanel.track('Table inserted', {
                  page_name: window.location.pathname,
                  location: 'Document Editor Toolbar',
                })
              }}
            >
              <Icon icon="lucide:table" width={20} />
            </div>

            <div
              className={`p-2 rounded text-black hover:bg-white/50 ${
                editor.isActive('image') ? 'bg-white' : 'bg-transparent'
              }`}
              onClick={() => {
                editor.chain().focus().run()
                if (window.documentEditImage) {
                  window.documentEditImage(null)
                } else {
                  onOpenMediaModal()
                }
                Mixpanel.track('Image inserted', {
                  page_name: window.location.pathname,
                  location: 'Document Editor Toolbar',
                })
              }}
            >
              <Icon icon="lucide:image" width={20} />
            </div>
          </div>
        </div>

        {/* Right DocMenuBar area */}
        <div className="flex h-full items-center justify-center w-[130px]">
          {saveMessage.text && (
            <div
              className={`flex items-center gap-2 bg-white text-black text-tiny rounded-full h-[28px] p-2 transition-opacity duration-200 ease-out ${
                saveMessage.visible ? 'opacity-100' : 'opacity-0'
              }`}
            >
              <span>{saveMessage.text}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
